export const ICONS = [
  { title: 'Human', iconId: 'icon-human' },
  { title: 'Lifebuoy', iconId: 'icon-lifebouy' },
  { title: 'Gear', iconId: 'icon-gear' },
  { title: 'Text T', iconId: 'icon-textT' },
  { title: 'Text Aa', iconId: 'icon-textAa' },
  { title: 'User', iconId: 'icon-user' },
  { title: 'User Settings', iconId: 'icon-settings' },
  { title: 'Wheelchair', iconId: 'icon-wheelchair' },
];
