/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import styled from '@emotion/styled';
import { Settings } from '@mui/icons-material';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import SelectedIcon from '../AccessibilityIcon/SelectedIcon';
import ErrorMessage from '../common/ErrorMessage';
import IconsDropdown from '../common/IconsDropdown/IconsDropdown';
import { PrimaryButton } from './Controls';
import { WidgetHeaderDisplay } from './widget-header';
import {
  contrastChecker,
  getIconSPositionAndAlignment,
} from '../../common/helpers';
import debounce from 'lodash-es/debounce';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  selectIconContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      fontSize: '12px',
      marginBottom: '5px',
    },
  },
  iconContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    width: '100%',
  },
  switchGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '12px',
    border: '2px solid #E4E7EB',
    padding: '8px 16px',
    maxHeight: 'fit-content',

    '& label': {
      height: 'max-content',
    },
    '& h6': {
      fontSize: '16px',
      fontWeight: '400',
      margin: 0,
    },
    '& span': {
      fontWeight: 400,
    },

    '& input:not(:checked) + .MuiSwitch-thumb': {
      border: '1px solid #747272',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomiseAppearance = ({
  setCustomAppearanceOpened,
  appearance,
  handleSaveAppearance,
  updateAppearance,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const colorRegex = /^#([0-9a-f]{3}){1,2}$/i;

  const [showIconDropdown, setShowIconDropdown] = useState(false);
  const anchorRef = useRef(null);
  const inputRef = useRef(null);

  const enableCustomAnchor =
    appearance.widgetConfigSiteAdditional.widgetActivationAnchor ===
    'icon-plus';

  const loading = useSelector((state) => state.domains.loading);

  const desktopScale =
    parseInt(appearance.widgetConfigSiteSize.diameterDesktop, 10) / 60;
  const mobileScale =
    parseInt(appearance.widgetConfigSiteSize.diameterMobile, 10) / 60;

  const handleIconPositionChange = (e) => {
    updateAppearance('widgetConfigSitePosition.alignment', e.target.value);
  };

  const handleHorizontalPosition = (e) => {
    updateAppearance(
      'widgetConfigSitePosition.horizontalMargin',
      e.target.value,
    );
  };

  const handleVerticalPosition = (e) => {
    updateAppearance('widgetConfigSitePosition.verticalMargin', e.target.value);
  };
  const handleMobileIconPositionChange = (e) => {
    updateAppearance('widgetConfigMobilePosition.alignment', e.target.value);
  };

  const handleMobileHorizontalPosition = (e) => {
    updateAppearance(
      'widgetConfigMobilePosition.horizontalMargin',
      e.target.value,
    );
  };

  const handleMobileVerticalPosition = (e) => {
    updateAppearance(
      'widgetConfigMobilePosition.verticalMargin',
      e.target.value,
    );
  };

  const debouncedUpdateAppearance = useMemo(
    () => debounce(updateAppearance, 50),
    [updateAppearance],
  );

  const handleIconActionColor = useCallback((e) => {
    const { value } = e.target;
    debouncedUpdateAppearance(
      {
        'widgetConfigSiteColoring.actionColor': value.trimEnd(),
        'widgetConfigSiteColoring.backgroundColor': value.trimEnd(),
      },
      '',
    );
  }, []);

  const handleHeaderColor = (e) => {
    const { value } = e.target;

    debouncedUpdateAppearance(
      {
        'widgetConfigSiteColoring.headerColor': value.trimEnd(),
        'widgetConfigSiteColoring.humanColor': value.trimEnd(),
      },
      '',
    );
  };

  const handleIconShapeChange = (e) => {
    updateAppearance('widgetConfigSiteSize.iconShape', e.target.value);
  };

  const handleIconBorder = (value) => {
    updateAppearance('widgetConfigSiteColoring.iconBorder', value);
  };

  const handleIconColor = (e) => {
    debouncedUpdateAppearance(
      'widgetConfigSiteColoring.iconObjectColor',
      e.target.value.trimEnd(),
    );
  };

  const handleIconRadius = (e) => {
    updateAppearance('widgetConfigSiteSize.diameterDesktop', e.target.value);
    setShowIconDropdown(false);
  };

  const handleMobileIconRadius = (e) => {
    updateAppearance('widgetConfigSiteSize.diameterMobile', e.target.value);
  };

  const handleIconDropdown = () =>
    setShowIconDropdown((prevState) => !prevState);

  const handleAnchor = (id) => {
    updateAppearance('widgetConfigSiteAdditional.widgetActivationAnchor', id);
  };

  const handleCloseDropdown = (e) => {
    const clickedEl = e.target;
    const dropdownClicked = clickedEl === anchorRef.current;
    if (showIconDropdown && !dropdownClicked) {
      setShowIconDropdown(false);
    }
  };

  const handleSetPresetIconColors = (fill, stroke) => {
    setShowIconDropdown(false);
    updateAppearance(
      {
        'widgetConfigSiteColoring.actionColor': fill,
        'widgetConfigSiteColoring.backgroundColor': fill,
        'widgetConfigSiteColoring.iconObjectColor': stroke,
      },
      '',
    );
  };

  const desktopStyles = getIconSPositionAndAlignment(
    appearance.widgetConfigSitePosition,
    desktopScale,
  );
  const mobileStyles = getIconSPositionAndAlignment(
    appearance.widgetConfigMobilePosition,
    mobileScale,
  );

  const saveAppearance = () => {
    handleSaveAppearance(false);
    setCustomAppearanceOpened(false);
  };

  const isValidContrast = contrastChecker(
    appearance.widgetConfigSiteColoring.actionColor,
    appearance.widgetConfigSiteColoring.iconObjectColor,
  );

  const isFormValid = enableCustomAnchor
    ? appearance.widgetConfigSiteAdditional.customAnchorSelector
    : isValidContrast;

  if (loading) {
    return (
      <LoadingDiv>
        <CircularProgress />
      </LoadingDiv>
    );
  }

  const handleEnableCustomAnchor = (isEnabled) => {
    if (isEnabled) {
      inputRef.current?.scrollIntoView();
    }

    updateAppearance(
      'widgetConfigSiteAdditional.widgetActivationAnchor',
      isEnabled ? 'icon-plus' : 'icon-human',
    );
  };

  return (
    <>
      <div
        className="customize-website-container"
        onClick={handleCloseDropdown}
      >
        <div className="customize-website-header">
          Desktop icon customization
        </div>
        <div className="customize-website-content">
          <div className="customize-website-content-fields">
            <div className="customize-website-content-fields-line">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Icon Position
                </InputLabel>
                <Select
                  native
                  value={appearance.widgetConfigSitePosition.alignment}
                  onChange={handleIconPositionChange}
                  label="Icon Position"
                  className={classes.select}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option value="top-left">Top-Left</option>
                  <option value="top-right">Top-Right</option>
                  <option value="bottom-left">Bottom-Left</option>
                  <option value="bottom-right">Bottom-Right</option>
                </Select>
              </FormControl>
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Horizontal Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleHorizontalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigSitePosition.horizontalMargin}
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Vertical Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleVerticalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigSitePosition.verticalMargin}
              />
            </div>
            <div
              className="customize-website-content-fields-line"
              ref={inputRef}
            >
              <TextField
                type="color"
                label="Header Color"
                variant="outlined"
                className={classes.input}
                onChange={handleHeaderColor}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigSiteColoring.headerColor}
                error={
                  !colorRegex.test(
                    appearance.widgetConfigSiteColoring.headerColor,
                  )
                }
              />
              {!colorRegex.test(
                appearance.widgetConfigSiteColoring.headerColor,
              ) && <ErrorMessage message="Please enter a valid color code" />}
            </div>
            {!enableCustomAnchor ? (
              <>
                <div className="customize-website-content-fields-line">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="icon-shape">Icon Shape</InputLabel>
                    <Select
                      native
                      value={appearance.widgetConfigSiteSize.iconShape}
                      onChange={handleIconShapeChange}
                      label="Icon Shape"
                      className={classes.select}
                      inputProps={{
                        name: 'shape',
                        id: 'icon-shape',
                      }}
                    >
                      <option value="circle">Circular</option>
                      <option value="rect">Square</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="customize-website-content-fields-line">
                  <TextField
                    type="color"
                    label="Icon Background Color"
                    variant="outlined"
                    className={classes.input}
                    onChange={handleIconActionColor}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={appearance.widgetConfigSiteColoring.actionColor}
                    error={
                      !colorRegex.test(
                        appearance.widgetConfigSiteColoring.actionColor,
                      )
                    }
                  />
                  {!colorRegex.test(
                    appearance.widgetConfigSiteColoring.actionColor,
                  ) && (
                    <ErrorMessage message="Please enter a valid color code" />
                  )}
                </div>
                <div className="customize-website-content-fields-line">
                  <TextField
                    type="color"
                    label="Icon Object Color"
                    variant="outlined"
                    className={classes.input}
                    onChange={handleIconColor}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={appearance.widgetConfigSiteColoring.iconObjectColor}
                  />
                </div>
                <div className="customize-website-content-fields-line">
                  <div className={classes.switchGroup}>
                    <label htmlFor="icon-border">
                      <h6>Icon Border</h6>
                    </label>
                    <Switch
                      inputProps={{
                        name: 'icon-border',
                        id: 'icon-border',
                      }}
                      disableRipple
                      onChange={() =>
                        handleIconBorder(
                          !appearance.widgetConfigSiteColoring.iconBorder,
                        )
                      }
                      checked={appearance.widgetConfigSiteColoring.iconBorder}
                    />
                  </div>
                </div>
                <div className="customize-website-content-fields-line">
                  <TextField
                    label="Icon Radius"
                    variant="outlined"
                    className={classes.input}
                    onChange={handleIconRadius}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={appearance.widgetConfigSiteSize.diameterDesktop}
                  />
                </div>
                <div className="customize-website-content-fields-line">
                  <div className="activation-anchor" ref={anchorRef}>
                    <h6 className="select-icon-label">Activation Anchor</h6>
                    <div
                      role="button"
                      className="select-icon-button"
                      aria-label="Activation Anchor"
                      onClick={handleIconDropdown}
                      onKeyPress={handleIconDropdown}
                      tabIndex={0}
                    >
                      <SelectedIcon
                        iconId={
                          appearance.widgetConfigSiteAdditional
                            .widgetActivationAnchor
                        }
                        width={45}
                        height={45}
                        fill={appearance.widgetConfigSiteColoring.actionColor}
                        stroke={
                          appearance.widgetConfigSiteColoring.iconObjectColor
                        }
                        showBorder={
                          appearance.widgetConfigSiteColoring.iconBorder
                        }
                        shape={appearance.widgetConfigSiteSize.iconShape}
                      />

                      {showIconDropdown && (
                        <IconsDropdown
                          selectedIcon={
                            appearance.widgetConfigSiteAdditional
                              .widgetActivationAnchor
                          }
                          width={45}
                          height={45}
                          actionColor={
                            appearance.widgetConfigSiteColoring.actionColor
                          }
                          iconColor={
                            appearance.widgetConfigSiteColoring.iconObjectColor
                          }
                          onSelect={handleAnchor}
                          showBorder={
                            appearance.widgetConfigSiteColoring.iconBorder
                          }
                          shape={appearance.widgetConfigSiteSize.iconShape}
                          onSetPresetIcon={handleSetPresetIconColors}
                          enableCustomAnchor={enableCustomAnchor}
                          onToggleEnableCustomAnchor={handleEnableCustomAnchor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="customize-website-content-fields-line">
                <div className="activation-anchor">
                  <CustomSelectorDiv>
                    <TextField
                      label="Custom Anchor Selector"
                      variant="outlined"
                      className={classes.input}
                      onChange={(evt) => {
                        updateAppearance(
                          'widgetConfigSiteAdditional.customAnchorSelector',
                          evt.target.value,
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        appearance.widgetConfigSiteAdditional
                          .customAnchorSelector
                      }
                    />
                    <SettingsDiv>
                      <Tooltip
                        title="Select Activation Anchor"
                        placement="top"
                        arrow
                      >
                        <Settings
                          role="button"
                          aria-hidden="false"
                          tabIndex={0}
                          onClick={() => {
                            handleEnableCustomAnchor(false);
                          }}
                        />
                      </Tooltip>
                    </SettingsDiv>
                  </CustomSelectorDiv>
                </div>
              </div>
            )}
          </div>

          {!isMobile && (
            <div style={{ width: '70%' }}>
              <div className="customize-website-content-view">
                <WidgetHeaderDisplay
                  color={appearance.widgetConfigSiteColoring.headerColor}
                />
              </div>
              {!enableCustomAnchor && (
                <>
                  <div
                    className="customize-website-content-view"
                    style={{ height: '150px', marginTop: '30px' }}
                  >
                    <div
                      className="customize-website-content-view-circle"
                      style={desktopStyles.circleStyles}
                    >
                      <SelectedIcon
                        iconId={
                          appearance.widgetConfigSiteAdditional
                            .widgetActivationAnchor
                        }
                        width={appearance.widgetConfigSiteSize.diameterDesktop}
                        height={appearance.widgetConfigSiteSize.diameterDesktop}
                        fill={appearance.widgetConfigSiteColoring.actionColor}
                        stroke={
                          appearance.widgetConfigSiteColoring.iconObjectColor
                        }
                        showBorder={
                          appearance.widgetConfigSiteColoring.iconBorder
                        }
                        shape={appearance.widgetConfigSiteSize.iconShape}
                      />
                    </div>
                  </div>
                  {!isValidContrast && (
                    <ErrorMessage message="The colors selected for the icon do not have sufficient contrast" />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="customize-website-container"
        onClick={handleCloseDropdown}
      >
        <div className="customize-website-header">
          Mobile icon customization
        </div>
        <div className="customize-website-content">
          <div className="customize-website-content-fields">
            <div className="customize-website-content-fields-line">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Icon Position
                </InputLabel>
                <Select
                  native
                  value={appearance.widgetConfigMobilePosition.alignment}
                  onChange={handleMobileIconPositionChange}
                  label="Icon Position"
                  className={classes.select}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option value="top-left">Top-Left</option>
                  <option value="top-right">Top-Right</option>
                  <option value="bottom-left">Bottom-Left</option>
                  <option value="bottom-right">Bottom-Right</option>
                  <option value="none">None</option>
                </Select>
              </FormControl>
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Horizontal Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileHorizontalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigMobilePosition.horizontalMargin}
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Vertical Margin"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileVerticalPosition}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigMobilePosition.verticalMargin}
              />
            </div>
            <div className="customize-website-content-fields-line">
              <TextField
                label="Icon Radius"
                variant="outlined"
                className={classes.input}
                onChange={handleMobileIconRadius}
                InputLabelProps={{
                  shrink: true,
                }}
                value={appearance.widgetConfigSiteSize.diameterMobile}
              />
            </div>
          </div>
          {!isMobile && (
            <div
              className="customize-website-content-view"
              style={{ width: '50%' }}
            >
              {appearance.widgetConfigMobilePosition.alignment !== 'none' && (
                <>
                  <div
                    className="customize-website-content-view-circle"
                    style={mobileStyles.circleStyles}
                  >
                    <SelectedIcon
                      iconId={
                        appearance.widgetConfigSiteAdditional
                          .widgetActivationAnchor
                      }
                      width={appearance.widgetConfigSiteSize.diameterMobile}
                      height={appearance.widgetConfigSiteSize.diameterMobile}
                      fill={appearance.widgetConfigSiteColoring.actionColor}
                      stroke={
                        appearance.widgetConfigSiteColoring.iconObjectColor
                      }
                      showBorder={
                        appearance.widgetConfigSiteColoring.iconBorder
                      }
                      shape={appearance.widgetConfigSiteSize.iconShape}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="add-website-fields">
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={saveAppearance}
          style={{ width: '83px' }}
          disabled={!isFormValid}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );
};

export default CustomiseAppearance;

CustomiseAppearance.propTypes = {
  domain: PropTypes.string.isRequired,
  setCustomAppearanceOpened: PropTypes.func.isRequired,
  handleSaveAppearance: PropTypes.func.isRequired,
  updateAppearance: PropTypes.func.isRequired,
  appearance: PropTypes.object.isRequired,
};

const CustomSelectorDiv = styled.div`
  position: relative;
`;

const SettingsDiv = styled.div`
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
  z-index: 50;
`;

const LoadingDiv = styled.div`
  height: 100%;
  min-height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
