import axios from 'axios';

export class PaymentsService {
  static getBusiness = async (businessId: number): Promise<any> => {
    let response;
    let error;
    const endpoint = `api/billing/v2/a/business/${businessId}/subscription/payments`;
    try {
      response = await axios.get(endpoint);
    } catch (err: any) {
      let errorMessage = 'Something went wrong!';
      if (err?.response?.data?.message) {
        const messageArray = err?.response?.data?.message.split('=');
        errorMessage = messageArray[messageArray.length - 1].trim();
      }
      console.error(errorMessage);
      error = errorMessage;
    }
    return {
      isSuccess: response?.status === 200,
      data: response?.data?.data || '',
      error,
    };
  };
}
