import styled from '@emotion/styled';
import { Modal } from '@equally-ai-front/common/src/components/modal/Modal';
import { cancelPlan } from '@equally-ai-front/common/src/redux/plans-slice/plans';
import { isStripePlan } from '@equally-ai/cart-checkout/src/utils/plans.utils';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import usePopup from '../hooks/use-pop-up';
import { setApiError, setApiSuccessMessage } from '../store/actions';
import { TypographyH1 } from '../components/common/styled';
import AllPlansTable from '../components/common/Tables/AllPlansTable';
import PopupComponent from '../components/redesign/alert-modal';
import { PrimaryButton } from '../components/redesign/Controls';
import { usePlans } from '../providers/plans-context';
import { NewPlansContainer } from './new-plans-container/new-plans-container';

const PlansContainer = () => {
  const { isOpen, showPopup, hidePopup, withCancel, withApproval } = usePopup();
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { activePlans: plansData, getActivePlans } = usePlans();
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(location.search);

  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  const handleCancelPlan = async () => {
    if (!queryParams.get('subscriptionId')) {
      return;
    }
    const subscriptionId = parseInt(queryParams.get('subscriptionId') || '0');
    if (subscriptionId === 0) return;

    const response = await dispatch(
      // @ts-ignore
      cancelPlan({ subscriptionId: subscriptionId }),
    );
    const { isSuccess } = response.payload;

    if (isSuccess) {
      dispatch(setApiSuccessMessage('Subscription was successfully canceled.'));
    } else {
      dispatch(
        setApiError('Could not cancel subscription, please reach out support'),
      );
    }

    navigate(`${location.pathname}`);
    hidePopup();
  };

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    const getPlans = async () => {
      const plans = await getActivePlans('');
      if (plans.length === 0) {
        setSearchParams({ show_plan: 'true' });
      }
    };
    void getPlans();
  }, [dispatch, currentBusiness]);

  const warmUp = (plan: any) => {
    navigate(`${location.pathname}?subscriptionId=${plan.subscription_id}`);
    showPopup();
  };

  const handleCancel = () => {
    navigate(`${location.pathname}`);
    hidePopup();
  };

  const onChoosePlan = () => {
    setSearchParams({ show_plan: 'true' });
  };

  const isOnlyStripePlans = useMemo(
    () =>
      plansData?.length > 0 &&
      plansData?.every((plan) => isStripePlan(plan.provider_id)),
    [plansData],
  );

  return (
    <>
      <Modal
        showModal={isOpen}
        handleClose={hidePopup}
        container={containerRef.current as Element}
      >
        <PopupComponent
          isOpen={isOpen}
          onApprove={() => withApproval(handleCancelPlan)}
          onCancel={() => withCancel(handleCancel)}
        />
      </Modal>
      <PopupComponent
        isOpen={isOpen}
        onApprove={() => withApproval(handleCancelPlan)}
        onCancel={() => withCancel(handleCancel)}
      />
      <PageHeader className="header-manage-websites settings-header">
        <div className="header-manage-websites-text">
          <HeadingTypography>Billing & Payments</HeadingTypography>
        </div>
        {plansData?.length === 0 && (
          <PrimaryButton
            variant="contained"
            color="primary"
            onClick={onChoosePlan}
          >
            Choose a plan
          </PrimaryButton>
        )}
      </PageHeader>

      {!isOnlyStripePlans && plansData ? (
        <AllPlansTable
          data={plansData}
          handleSelectPlan={() => {}}
          onCancelPlan={warmUp}
        />
      ) : (
        <NewPlansContainer plans={plansData} />
      )}
    </>
  );
};

export default PlansContainer;

const HeadingTypography = styled(TypographyH1)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;
`;

const PageHeader = styled.div`
  padding-top: 10px;
`;
