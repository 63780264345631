import { DevDomain, Domain } from '@equally-ai-front/common/src/types/domains';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { IssueType } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DashboardFilterStatus,
  DashboardReportType,
  DashboardState,
  GptSuggestionAction,
  UpdatePromptsPayload,
} from './types';
import { nativeHTMLElements } from '../../containers/dashboard-container/const';

import ChatGPT from '@equally-ai-front/common/src/api/chat-gpt';
import { FeedbackSuggestionApiPayload } from '@equally-ai-front/common/src/types/chat-gpt';
import { OverviewAPI } from '@equally-ai-front/common/src/api/overview-api';
import { ApiResponse } from '@equally-ai-front/common/src/utils/axios';

export const initialState: DashboardState = {
  reportType: 'html',
  severityFilter: 'all',
  currentIssueStatus: 'pending',
  nativeHTMLElementsFilter: nativeHTMLElements,
  currentScan: undefined,
  currentDevDomain: undefined,
  currentReportUrl: undefined,
  gptSuggestion: undefined,
  isGptLoading: false,
  customPrompts: {},
  domain: undefined,
  loading: false,
  pageScans: [],
  scannedUrls: [],
  scanResults: [],
};
// export const getAuditIssues = createAsyncThunk(
//   `services/getAuditIssues`,
//   async (actions: any, { rejectWithValue }) => {
//     const { domainId, businessId } = actions;
//     const { error, data, isSuccess } = await Audit.getManualAuditIssues(
//       domainId,
//       businessId,
//     );
//     if (isSuccess) {
//       return data;
//     }
//     return rejectWithValue(error);
//   },
// );

export const getGPTSuggestion = createAsyncThunk(
  `gpt/fetch-suggestion`,
  async (actions: GptSuggestionAction, { getState, rejectWithValue }) => {
    const currentState: any = getState();
    const payload = { ...actions };
    const customPrompt = currentState.dashboard.customPrompts[actions.gptType];

    if (customPrompt) {
      payload.prompt = customPrompt;
    }

    const { error, data, isSuccess } = await ChatGPT.sendPrompt(payload);

    actions.notify(isSuccess, actions.gptType, error?.message || '');
    if (isSuccess) {
      return {
        ...data,
        issue_id: actions.issue_id,
        gptType: actions.gptType,
        currentSuggestionState: currentState.dashboard.gptSuggestion || {},
      };
    }
    return rejectWithValue(error);
  },
);

export const getPageScanHistory = createAsyncThunk(
  'get/page-scan-history',
  async (payload: {
    businessId: number;
    websiteId: number;
    pageUrl: string;
  }): Promise<ApiResponse<Scan[]>> => {
    let result;
    let apiError;
    const { businessId, websiteId, pageUrl } = payload;

    try {
      result = await OverviewAPI.getAllDevDomainScans(
        businessId,
        websiteId,
        pageUrl,
      );
    } catch (err) {
      console.error(err);
      apiError = "Couldn't get total scans";
    }

    const { data, error, isSuccess } = result || {};
    return {
      data,
      error: error || apiError,
      isSuccess: isSuccess || false,
    };
  },
);

export const sendGptFeedback = createAsyncThunk(
  `gpt/send-feedback`,
  async (actions: FeedbackSuggestionApiPayload, { rejectWithValue }) => {
    const { error, data, isSuccess } = await ChatGPT.feedbackPrompt(actions);

    // actions.notify(isSuccess, actions.gptType, error || '');
    if (isSuccess) {
      return data;
    }
    return rejectWithValue(error);
  },
);

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCurrentDevDomain(state, action: PayloadAction<DevDomain>) {
      state.currentDevDomain = action.payload;
    },
    setIssueSeverityFilter(state, action: PayloadAction<IssueType>) {
      state.severityFilter = action.payload;
    },
    setReportType(state, action: PayloadAction<DashboardReportType>) {
      state.reportType = action.payload;
    },
    setCurrentReportUrl(state, action: PayloadAction<string>) {
      state.currentReportUrl = action.payload;
    },

    setIssueStatuses(state, action: PayloadAction<DashboardFilterStatus>) {
      state.currentIssueStatus = action.payload;
    },

    setNativeHTMLElementsFilter(state, action: PayloadAction<string[]>) {
      state.nativeHTMLElementsFilter = action.payload;
    },

    setDomain(state, action: PayloadAction<Domain>) {
      state.domain = action.payload;
    },
    setCurrentScan(state, action: PayloadAction<Scan | undefined>) {
      state.currentScan = action.payload;
    },
    resetDashboardState() {
      return initialState;
    },
    updateCustomPrompts(state, action: PayloadAction<UpdatePromptsPayload>) {
      state.customPrompts[action.payload.gptType] = action.payload.prompt;
    },
    setScannedUrls(state, action: PayloadAction<Scan[]>) {
      const newScannedUrls = action.payload;

      return {
        ...state,
        scannedUrls: newScannedUrls,
      };
    },
    setScanResults(state, action: PayloadAction<Scan[]>) {
      return {
        ...state,
        scanResults: action.payload,
      };
    },
  },
  extraReducers: {
    [getGPTSuggestion.pending.type]: (state) => {
      state.isGptLoading = true;
    },
    [getGPTSuggestion.fulfilled.type]: (state, action) => {
      state.isGptLoading = false;

      const tempState = {
        ...action.payload.currentSuggestionState,
        [action.payload.issue_id]: {
          ...(action.payload.currentSuggestionState[action.payload.issue_id] ||
            {}),
          [action.payload.gptType]: action.payload,
        },
      };

      state.gptSuggestion = tempState;
    },
    [getGPTSuggestion.rejected.type]: (state) => {
      state.isGptLoading = false;
    },

    [sendGptFeedback.pending.type]: (state) => {
      state.isGptLoading = true;
    },
    [sendGptFeedback.fulfilled.type]: (state) => {
      state.isGptLoading = false;
    },
    [sendGptFeedback.rejected.type]: (state) => {
      state.isGptLoading = false;
    },
    [getPageScanHistory.pending.type]: (state) => {
      state.loading = true;
    },
    [getPageScanHistory.fulfilled.type]: (state, action) => {
      state.pageScans = action.payload?.data || [];
      state.loading = false;
    },
    [getPageScanHistory.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setCurrentDevDomain,
  setIssueSeverityFilter,
  setCurrentReportUrl,
  setReportType,
  setIssueStatuses,
  setNativeHTMLElementsFilter,
  setCurrentScan,
  resetDashboardState,
  updateCustomPrompts,
  setDomain,
  setScannedUrls,
  setScanResults,
} = slice.actions;
export default slice.reducer;
