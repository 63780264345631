import React from 'react';

import TextLink from '@equally-ai-front/common/src/components/TextLink';
import styled from 'styled-components';
import { PrimaryButton } from '../../../redesign/Controls';
import { getUrlWithoutHttpPrefixAndWWW } from '@equally-ai-front/common/src';

export interface StatementViewProps {
  planId: number;
  name: string;
  defaultLanguage: string;
  onChoosePlan: (productType: string) => void;
  statementLink: string;
  type: string;
}
export const StatementView = (props: StatementViewProps) => {
  const { planId, name, defaultLanguage, statementLink, onChoosePlan } = props;

  const hasCustomLink = statementLink?.trim() !== '';

  const viewStatementLink = hasCustomLink
    ? `https://${getUrlWithoutHttpPrefixAndWWW(statementLink)}`
    : `statement?domain=${encodeURIComponent(name)}&lang=${encodeURIComponent(
        defaultLanguage,
      )}`;

  return planId === 0 ? (
    // @ts-ignore
    <UpgradeButton onClick={() => onChoosePlan(type)}>Upgrade</UpgradeButton>
  ) : (
    // @ts-ignore
    <StatementLink
      target={hasCustomLink ? '_blank' : '_parent'}
      to={viewStatementLink}
      rel="noopener noreferrer"
    >
      Certification
    </StatementLink>
  );
};
const StatementLink = styled(TextLink)`
  font-weight: 400;

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`;

const UpgradeButton = styled(PrimaryButton)`
  font-weight: 400;
  display: inline-block;
  padding: 0;
  height: fit-content;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  //&:hover {
  //  background: transparent;
  //  border-bottom: 2px solid #6966b2;
  //}
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`;
