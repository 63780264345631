import React from 'react';
import { Logout } from './containers/logout/Logout';
import { AppRedirect } from './containers/app-redirect';
import { BusinessFormContainer } from './containers/on-boarding-container/on-boarding-container';
import { ProtectedRoute } from './containers/protected-route';
// import { UnderMaintenanceContainer } from './containers/under-maintenance-container/under-maintenance-container';

const elements = [
  {
    element: <Logout />,
    path: '/logout',
  },
  {
    element: <AppRedirect />,
    path: '/*',
  },
  {
    element: (
      <ProtectedRoute>
        <BusinessFormContainer />
      </ProtectedRoute>
    ),
    path: '/on-boarding/*',
  },
];

export const routes = elements;
