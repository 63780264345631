import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../const';
import { Box } from '@mui/material';

interface StripeCheckoutProps {
  clientSecret: string;
}
export const StripeCheckout = ({
  clientSecret,
}: StripeCheckoutProps): JSX.Element => {
  const [stripePromise, setStripePromise] = useState<any>(null);

  useEffect(() => {
    if (!STRIPE_PUBLISHABLE_KEY) {
      throw new Error('Stripe publishable key is not set');
    }

    setStripePromise(loadStripe(STRIPE_PUBLISHABLE_KEY));
  }, []);

  const options = {
    clientSecret,
  };

  if (!stripePromise) {
    return <></>;
  }

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <Box mb={2}>
        <EmbeddedCheckout />
      </Box>
    </EmbeddedCheckoutProvider>
  );
};
