import axios from 'axios';
import { ApiResponse } from '@equally-ai-front/axios-api';
import { StripeSessionResponse } from '../types';

export class StripeService {
  static async getCheckoutSession(value: {
    businessId: number;
    providerId: number;
    cartId: number;
    checkoutId: number;
  }): Promise<any> {
    let result;
    let error;
    const endpoint = `api/billing/v2/a/business/${value.businessId}/checkout/session`;
    const payload = {
      provider_id: value.providerId,
      cart_id: value.cartId,
      checkout_id: value.checkoutId,
    };
    try {
      result = await axios.post(endpoint, payload);
    } catch (err: any) {
      let errorMessage = 'Something went wrong!';
      if (err?.response?.data?.message) {
        const messageArray = err?.response?.data?.message.split('=');
        errorMessage = messageArray[messageArray.length - 1].trim();
      }
      console.error(errorMessage);
      error = errorMessage;
    }
    const data = result?.data?.data || '';
    return {
      isSuccess: result?.data?.code === 200,
      data,
      error,
    };
  }

  static async confirmPayment(value: {
    businessId: number;
    providerId?: number;
    sessionId: string;
  }): Promise<ApiResponse<StripeSessionResponse>> {
    let result;
    let error;
    const endpoint = `api/billing/v2/a/business/${value.businessId}/checkout/session?session_id=${value.sessionId}&provider_id=3`;
    try {
      result = await axios.get(endpoint);
    } catch (err: any) {
      let errorMessage = 'Something went wrong!';
      if (err?.response?.data?.message) {
        const messageArray = err?.response?.data?.message.split('=');
        errorMessage = messageArray[messageArray.length - 1].trim();
      }
      console.error(errorMessage);
      error = errorMessage;
    }
    const data = result?.data?.data || '';
    return {
      // @ts-ignore
      isSuccess: result?.statusCode === 200,
      data,
      error,
    };
  }
}
