import { currentBusinessUpdated } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Business } from '@equally-ai-front/common/src/types/business';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AriaLazy, Developers, Statement } from '../components';
import { WidgetContainer } from '../containers/widget-container/widget-container';
import useBusinessRedirect from '../hooks/use-business-redirect';
import { isUserPermitted } from '../lib/permissions';
import { BusinessSettingsRoutes } from './business-settings-container/business-settings-routes';
import { ComplianceManagerRedirect } from './compliance-manager-redirect';
import { PlansProvider } from '../providers/plans-context';
import { getHasActiveDevDomains } from '../utils/domain.utils';
import { RootState } from '../store';
import { ContainerWithLayout } from './app-redirect';
import { DomainInstallation } from '../components/domain-installation/installation';
import { PricingContainer } from './pricing-container/pricing-container';
import { SettingsRedirect } from './settings-redirect';
import { StripePaymentConfirmation } from '@equally-ai/cart-checkout/src/components/stripe-payment-confirmation';
import { setApiError } from '../store/actions';
import { useAnalytics } from '@equally-ai-front/common/src';
import { StripeSessionResponse } from '@equally-ai/cart-checkout/src/types';

interface BusinessRedirectProps {}

interface RoutesWithPermissionsConfig {
  condition: boolean;
  path: string;
  element: React.ReactNode;
}

export const BusinessRedirect = (props: BusinessRedirectProps) => {
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );
  const analytics = useAnalytics();
  const domains = useSelector((state: RootState) => state.userProducts.domains);

  const [searchParams, setSearchParams] = useSearchParams();

  const showPlan = searchParams.get('show_plan') === 'true';

  const onClosePricing = () => {
    searchParams.delete('show_plan');
    setSearchParams(searchParams);
  };

  const { businessSlug } = useParams();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const businesses = useSelector((state: any) => state.business.businesses);
  const navigate = useNavigate();
  const [showBusinessForm, setShowBusinessForm] = useState(false);
  const dispatch = useDispatch();

  const closeBusinessFormModal = () => {
    setShowBusinessForm(false);
  };

  const handlePaymentConfirmationErrroMessage = (errorMessage: string) => {
    dispatch(setApiError(errorMessage));
  };

  const onPaymentConfirmation = (subscription: StripeSessionResponse) => {
    analytics.track('New Subscription Created - FE', {
      planId: subscription.subscription_external_id,
      planPrice: subscription.total_price,
      subscription_id: subscription.subscription_external_id,
      price: subscription.total_price,
      currency: subscription.currency,
      free_trial_period: subscription.expiration_date,
    });
  };

  useBusinessRedirect({ currentBusiness, businessSlug, personalDetails });
  useEffect(() => {
    if (!businesses || businesses?.length === 0) {
      return;
    }
    let newSlug = null;
    if (currentBusiness) {
      newSlug = currentBusiness.slug;
    } else if (currentBusiness?.slug !== businessSlug || !currentBusiness) {
      const existingBusiness =
        businesses.find(
          (business: Business) => business.slug === businessSlug,
        ) || businesses[0];
      newSlug = existingBusiness.slug;
      dispatch(currentBusinessUpdated(existingBusiness));
    }

    if (newSlug && newSlug !== businessSlug) {
      navigate(`/${newSlug}`);
      console.log('settings brand slug app redirect');
    }
  }, [currentBusiness, businesses, dispatch, navigate, businessSlug]);

  const routesWithPermissions: RoutesWithPermissionsConfig[] = useMemo(() => {
    if (!domains || !currentBusiness || !personalDetails) return [];

    const hasActiveDevDomains = getHasActiveDevDomains(domains || []);
    return [
      {
        path: `developers/:domainId/flowy/*`,
        element: (
          <ContainerWithLayout view={'flowy'}>
            <Developers />
          </ContainerWithLayout>
        ),
        condition: isUserPermitted(
          currentBusiness?.id,
          ['view.flowy_page'],
          personalDetails,
        ),
      },
      {
        path: `compliance-manager/*`,
        element: (
          <ContainerWithLayout view={'compliance'}>
            <ComplianceManagerRedirect />
          </ContainerWithLayout>
        ),
        condition:
          isUserPermitted(
            currentBusiness?.id,
            ['view.compliance_page'],
            personalDetails,
          ) && hasActiveDevDomains,
      },
      {
        path: `business-settings/*`,
        element: (
          <ContainerWithLayout>
            <BusinessSettingsRoutes />
          </ContainerWithLayout>
        ),
        condition: isUserPermitted(
          currentBusiness?.id,
          ['view.business_settings'],
          personalDetails,
        ),
      },
    ];
  }, [currentBusiness, domains, personalDetails]);

  if (businesses && businesses.length === 0) {
    return <Navigate to="/on-boarding" replace />;
  }

  return (
    <PlansProvider businessId={currentBusiness?.id}>
      <Routes>
        <Route
          path="installation"
          element={
            <ContainerWithLayout>
              <DomainInstallation />
            </ContainerWithLayout>
          }
        />
        <Route
          index
          element={
            <ContainerWithLayout>
              <WidgetContainer />
            </ContainerWithLayout>
          }
        />
        {/*<Route path={`services`} element={<ContainerWithLayout><Services /></ContainerWithLayout>} />*/}
        <Route
          path={`statement`}
          element={
            <ContainerWithLayout>
              <Statement />
            </ContainerWithLayout>
          }
        />
        <Route
          path={`settings/*`}
          element={
            <ContainerWithLayout>
              <SettingsRedirect />
            </ContainerWithLayout>
          }
        />
        <Route
          path={`audit`}
          element={
            <ContainerWithLayout>
              <AriaLazy />
            </ContainerWithLayout>
          }
        />

        {routesWithPermissions.map(({ condition, path, element }) => (
          <Route
            key={path}
            path={path}
            element={condition ? element : <Navigate to="/" replace />}
          />
        ))}
      </Routes>
      {showPlan && (
        <PricingContainer
          productType={'widget'}
          showModal={showPlan}
          handleClose={onClosePricing}
        />
      )}

      {currentBusiness && (
        <StripePaymentConfirmation
          businessId={currentBusiness.id}
          onError={handlePaymentConfirmationErrroMessage}
          onConfirm={onPaymentConfirmation}
        />
      )}
    </PlansProvider>
  );
};
