import React from 'react';
import { ReactComponent as CloseIcon } from '../../img/icons/close-icon-white.svg';
import { withStyles } from '@mui/styles';
import { ReactComponent as EquallyAILogo } from '../../img/icons/logo-black.svg';

import {
  IconButton,
  Tab,
  Tabs,
  List,
  Box,
  Drawer,
  CssBaseline,
} from '@mui/material';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isUserPermitted } from '../../lib/permissions';
import { getHasActiveDevDomains } from '../../utils/domain.utils';
import styled from 'styled-components';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { useMemo } from 'react';
import {
  getAppLinks,
  getComplianceManagerLinks,
  getFlowyLinks,
  NavBarLink,
} from './consts';
import { FlowyView } from './views/flowy';
import { NavMenuItem } from './common/nav-menu-item';
import { ComplianceManagerView } from './views/compliance-manager';
import { usePlans } from '../../providers/plans-context';
import { showPlans } from '@equally-ai/cart-checkout/src/utils/plans.utils';

export type NavigationBarView = 'flowy' | 'compliance' | 'app';
interface NavigationBarProps {
  isMobileOpen: boolean;
  handleDrawerClose: () => void;
  handleDrawerTransitionEnd: () => void;
  handleDrawerToggle: () => void;
  isAuthenticated: boolean;
  drawerWidth: number;
  view: NavigationBarView;
}

export const NavigationBar = (props: NavigationBarProps) => {
  const {
    isMobileOpen,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    handleDrawerToggle,
    isAuthenticated,
    drawerWidth,
    view,
  } = props;
  const location = useLocation();
  const currentBusiness = useSelector(
    (state: RootState) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: RootState) => state.personalDetails.personalDetails,
  );
  const domains = useSelector((state: RootState) => state.userProducts.domains);
  const isOnMobile = useMediaQuery('@media screen and (max-width: 800px)');

  const hasActiveDevDomains = getHasActiveDevDomains(domains || []);

  const { activePlans } = usePlans();

  const enablePlanQueryParam = showPlans(activePlans || []);

  const devDomainId = useParams()?.domainId || null;
  const complianceManagerLinks: NavBarLink[] = useMemo(() => {
    return [
      ...(isUserPermitted(
        currentBusiness?.id,
        ['view.compliance_page'],
        personalDetails,
      ) && hasActiveDevDomains
        ? getComplianceManagerLinks(currentBusiness)
        : []),
    ];
  }, [hasActiveDevDomains, currentBusiness]);
  const flowyLinks: NavBarLink[] = useMemo(() => {
    return [
      ...(hasActiveDevDomains
        ? getFlowyLinks(currentBusiness, devDomainId || '')
        : []),
    ];
  }, [hasActiveDevDomains, currentBusiness, devDomainId]);

  const navBarLinks: NavBarLink[] | undefined = useMemo(() => {
    if (view === 'compliance') {
      return complianceManagerLinks;
    }
    if (view === 'flowy') {
      return flowyLinks;
    }
    return getAppLinks(
      personalDetails,
      hasActiveDevDomains,
      enablePlanQueryParam,
      currentBusiness,
    );
  }, [
    view,
    complianceManagerLinks,
    flowyLinks,
    currentBusiness,
    enablePlanQueryParam,
  ]);

  const getView = (view: NavigationBarView) => {
    return {
      flowy: <FlowyView isOnMobile={isOnMobile} />,
      compliance: <ComplianceManagerView isOnMobile={isOnMobile} />,
      app: <></>,
    }[view];
  };
  const drawer = (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: `${isMobileOpen ? '10px' : '16px'}`,
        padding: 0,
        marginTop: `${isMobileOpen ? '16px' : ''}`,
      }}
    >
      {navBarLinks &&
        navBarLinks.map(
          ({ text, linkTo, icon, disableMainRoute, subRoutes }, index) => {
            const isActive = location.pathname === linkTo.split('?')[0];
            return (
              <NavMenuItem
                handleDrawerClose={handleDrawerClose}
                isMobileOpen={isMobileOpen}
                icon={icon}
                text={text}
                isActive={isActive}
                index={index}
                linkTo={linkTo}
                subRoutes={subRoutes}
                disableMainRoute={disableMainRoute}
              />
            );
          },
        )}
      <>{getView(view)}</>
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          display: `${isAuthenticated ? '' : 'none'}`,
          '@media screen and (min-width: 800px)': {
            width: drawerWidth,
            flexShrink: 0,
          },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={isMobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          // onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: 'block',
            zIndex: '1300',

            '@media screen and (min-width: 800px)': {
              display: 'none',
            },

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              padding: '34px 0 17px 30px',

              '@media screen and (max-width: 800px)': {
                top: 0,
                padding: 0,
                width: '310px',
              },
            },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: 'none',
              '@media (max-width:800px)': {
                display: 'flex',
                position: 'fixed',
                padding: 0,
                top: '30px',
                left: '83.7%',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              borderBottom: '1px solid #E0E0E0',
              height: '83px',
              padding: '34px 0 17px 30px',
            }}
          >
            <EquallyAILogo />
          </Box>
          <>{drawer}</>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: 'none',
            '@media screen and (min-width: 800px)': {
              display: 'block',
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              top: '90px',
              padding: '30px 22px',
              height: 'calc(100vh - 90px)',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

const FilterTextDiv = withStyles({
  root: {
    marginBottom: '10%',
    borderTop: '2px solid #e8e8e8',

    '& .MuiTabs-flexContainer': {
      flexDirection: 'column',

      '& .MuiTab-root': {
        maxWidth: '100%',
        borderBottom: '2px solid #e8e8e8',
        paddingLeft: '6%',

        '& .MuiTab-wrapper': {
          fontSize: 13,
          '& > *:first-of-type': {
            marginBottom: 0,
          },
        },

        '&.Mui-selected': {
          borderBottom: '2px solid #1890ff',
        },
      },
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const TabLabel = styled.span``;

const TabIcon = styled.img`
  margin-right: 15px;
`;

const CustomTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
