import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { StripeService } from '../api/stripe';
import { CheckoutStatuses, StripeSessionResponse } from '../types';

interface PaymentConfirmationProps {
  onError: (value: string) => void;
  businessId: number;
  onConfirm: (subscription: StripeSessionResponse) => void;
}

type PaymentSummaryHeaders = {
  header: string;
  getValue: (session: StripeSessionResponse) => JSX.Element;
};

const FIRST_SUMMARY_HEADERS: PaymentSummaryHeaders[] = [
  {
    header: 'Plan name',
    getValue: (session) => <span>{session.plan_template_name}</span>,
  },
  {
    header: 'Credits',
    getValue: (session) => <span>{session.credits}</span>,
  },
  {
    header: 'Billing cycle',
    getValue: (session) => (
      <span>Every {session.duration_unit?.toLowerCase()}</span>
    ),
  },
];

const SECOND_SUMMARY_HEADERS: PaymentSummaryHeaders[] = [
  {
    header: 'Payment method',
    getValue: (session) => (
      <span style={{ textTransform: 'capitalize' }}>
        {session.payment_method.type}
      </span>
    ),
  },
  {
    header: 'Total price',
    getValue: (session) => (
      <span>
        {session.total_price} {session?.currency}
      </span>
    ),
  },
];

export const StripePaymentConfirmation = ({
  businessId,
  onError,
  onConfirm,
}: PaymentConfirmationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  const [session, setSession] = useState<StripeSessionResponse | undefined>(
    undefined,
  );

  const isMountedRef = useRef(true);

  const onClose = () => {
    setSearchParams((prev) => {
      prev.delete('session_id');
      return prev;
    });
  };

  useEffect(() => {
    if (!businessId || !sessionId) {
      return;
    }

    const getSession = async (retries = 5) => {
      // prevent infinite loops
      if (retries <= 0) {
        onError('Something went wrong!');
        onClose();
        return;
      }

      const { data, error } = await StripeService.confirmPayment({
        businessId: businessId,
        sessionId,
      });

      if (!isMountedRef.current) return;

      if (retries - 1 === 0) {
        onError(error || 'Something went wrong!');
        onClose();
        return;
      }

      if (data?.status !== CheckoutStatuses.COMPLETE || error) {
        setTimeout(() => getSession(retries - 1), 1000);
        return;
      }

      setSession(data);
      onConfirm(data);
    };

    void getSession();

    return () => {
      isMountedRef.current = false;
    };
  }, [businessId, sessionId]);

  if (!sessionId) {
    return null;
  }

  return (
    <StyledDialog
      open
      onClose={onClose}
      sx={{
        minHeight: '400px',
        maxWidth: '90%',
        padding: '20px',
      }}
    >
      {!session ? (
        <Box
          display="flex"
          height="100%"
          minHeight="400px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <CenterBlock pt="25px">
            <CheckCircleOutlineOutlinedIcon
              color="primary"
              sx={{ width: '80px', height: '80px' }}
            />
            <Heading color="primary" variant="h3">
              Payment Successful
            </Heading>
            <Typography
              component="h5"
              variant="h5"
              style={{ fontSize: '20px' }}
            >
              Thank you for your purchase
            </Typography>
          </CenterBlock>
          <Box mt="25px" />
          <CenterBlock mb="25px">
            {FIRST_SUMMARY_HEADERS.map((summary) => (
              <SmallText key={summary.header.split(' ').join('_')}>
                <span>{summary.header}</span>
                {summary.getValue(session)}
              </SmallText>
            ))}
          </CenterBlock>
          <Divider sx={{ borderWidth: '1px' }} />
          <CenterBlock pb="25px" pt="8px">
            {SECOND_SUMMARY_HEADERS.map((summary) => (
              <SmallText key={summary.header.split(' ').join('_')}>
                <span>{summary.header}</span>
                {summary.getValue(session)}
              </SmallText>
            ))}
          </CenterBlock>
        </Box>
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  overflow: hidden;
  max-width: 100%;

  & .MuiDialog-paper {
    max-width: 900px;
    min-height: 400px;
    min-width: 450px;
    border-radius: 12px;

    @media (pointer: none), (pointer: coarse) {
      max-height: 93vh;
      border-radius: 8px;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      min-width: 95%;
      max-width: 100%;
      margin: 0;
    }
  }
`;

const Heading = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  margin-top: 12px;
`;

const CenterBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  width: 80%;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

const SmallText = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;
