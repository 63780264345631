import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ALL_PLANS_OPTION,
  INACTIVE_PLANS_OPTION,
} from '../../../common/magicValues';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { PrimaryButton, PrimaryMobileButton } from '../../redesign/Controls';
import { HeadingTypography } from '../heading';
import LoadingView from '../LoadingView';
import PlanDetails from './PlanDetails';
import { usePlans } from '../../../providers/plans-context';

type PlansAndRecordsType = 'widget' | 'developer';

interface PlansAndRecordsProps {
  type: PlansAndRecordsType;
  heading: string;
  selectedPlan: string;
  searchValue: string;
  handleSearch: (query: string) => void;
  showActionButton: boolean;
  attachPlanButtonLabel: string;
  handleAttachPlanClick: () => void;
  handleActionButton: () => void;
  children: React.ReactNode;
}

export const PlansAndRecords = ({
  type,
  heading,
  selectedPlan,
  showActionButton,
  attachPlanButtonLabel,
  handleAttachPlanClick,
  handleActionButton,
  children,
}: PlansAndRecordsProps) => {
  const { activePlans: plansData } = usePlans();
  const isLoading = useSelector((state: any) => state.plans.loading);

  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const getSelectedPlan = () =>
    (plansData || []).find((plan: any) => plan.id === selectedPlan);

  const getActionButton = () => {
    return isMobile ? PrimaryMobileButton : PrimaryButton;
  };
  const ActionButton = getActionButton();

  return (
    <>
      <div className="header-manage-websites widget-container-header">
        <div className="header-manage-websites-text widget-container-header-text">
          <HeadingTypography>{heading}</HeadingTypography>
        </div>
        <div>
          <ButtonsContainer>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={handleActionButton}
              aria-haspopup="dialog"
              style={{ minHeight: '50px' }}
            >
              + Add new website
            </ActionButton>
            {showActionButton && attachPlanButtonLabel !== '' && (
              <ActionButton
                variant="outlined"
                onClick={handleAttachPlanClick}
                style={{
                  borderRadius: '8px',
                  border: '2px solid #454284',
                  color: '#454284',
                }}
              >
                {attachPlanButtonLabel}
              </ActionButton>
            )}
            {/* <>
              {handleSearch && (
                <StyledFormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="search-input"
                    style={{ visibility: 'hidden', display: 'none' }}
                  >
                    Search
                  </InputLabel>
                  <StyledInput
                    id="search-input"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchOutlined
                          style={{
                            fill: '#000A14D9',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </StyledFormControl>
              )}
            </> */}
          </ButtonsContainer>
        </div>
      </div>

      <LoadingView open={isLoading} />

      {selectedPlan !== ALL_PLANS_OPTION &&
        selectedPlan !== INACTIVE_PLANS_OPTION && (
          <PlanDetails plan={getSelectedPlan()} type={type} />
        )}
      {children}
    </>
  );
};

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '10px',

  '& > button': {
    padding: '6px 16px 6px 12px',

    [theme.breakpoints.down('xs')]: {
      height: '36px',
      padding: '6px 12px',
      fontSize: '15px',
    },
  },

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'flex-start',
  },
}));
