import { Box, Typography } from '@mui/material';
import React from 'react';
import { TypographyH4, TypographySubtitle } from '../common/styled';
import moment from 'moment';
import styled from '@emotion/styled';
import { PrimaryMobileButton } from '../redesign/Controls';

interface MainPlanViewProps {
  name: string;
  price: number;
  credits: number;
  domainSize: number;
  durationUnit: string;
  expirationDate: string;
  onClick?: () => void;
}

const MainPlanView = ({
  name,
  credits,
  domainSize,
  durationUnit,
  expirationDate,
  price,
  onClick,
}: MainPlanViewProps) => {
  return (
    <Box py={2}>
      <Typography variant="h6" gutterBottom style={{ margin: '1vh 0 2vh 0' }}>
        Plan
      </Typography>
      <Container display="flex" justifyContent="space-between">
        <div>
          <Heading>{name}</Heading>
          <LightText>
            ${price} / {durationUnit?.toLowerCase()}
          </LightText>
          <CreditsText>
            Credits -{' '}
            <span>
              {credits}/{domainSize}
            </span>
          </CreditsText>
        </div>
        <div>
          <Heading>Billing period</Heading>
          <BillingText>
            <span style={{ textTransform: 'capitalize' }}>
              {`${durationUnit?.toLowerCase()}ly`}
            </span>{' '}
            <span style={{ color: 'rgba(0, 10, 20, 0.65)' }}>
              (renews on {moment(expirationDate).format('DD MMMM YYYY')})
            </span>
          </BillingText>
        </div>
      </Container>
      <Box mt={2} />
      {onClick && (
        <PrimaryMobileButton variant="outlined" onClick={onClick}>
          Manage Plan
        </PrimaryMobileButton>
      )}
      {/*<PlanCard/>*/}
    </Box>
  );
};

export default MainPlanView;

const Container = styled(Box)`
  flex-wrap: wrap;

  @media only screen and (min-width: 968px) {
    max-width: 60%;
  }
`;

const Heading = styled(TypographyH4)`
  font-size: 18px;
  font-weight: 600;
`;

const LightText = styled(TypographySubtitle)`
  //color: rgba(0, 10, 20, 0.65);
  font-size: 16px;
  margin-top: 8px;
  font-weight: 500;
`;

const CreditsText = styled(TypographySubtitle)`
  font-size: 16px;
  margin-top: 8px;
  font-weight: 500;
`;

const BillingText = styled(TypographySubtitle)`
  font-size: 16px;
  margin-top: 6px;
  font-weight: 500;
`;
