import { Box, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { createSpaceOrEnterHandler } from '../../../common/helpers';
import { ICONS } from '../../../common/icons';
import SelectedIcon from '../../AccessibilityIcon/SelectedIcon';
import { DropdownContainer } from './styled';

const PRESET_ICON_CONFIG = [
  { fill: '#ffffff', stroke: '#b62c09' },
  { fill: '#0f6bff', stroke: '#ffffff' },
  { fill: '#8f6304', stroke: '#ffffff' },
  { fill: '#ffffff', stroke: '#8f6304' },
  { fill: '#7c4ed2', stroke: '#ffffff' },
];

const IconsDropdown = ({
  selectedIcon,
  width,
  height,
  actionColor,
  onSelect,
  showBorder,
  shape,
  onSetPresetIcon,
  iconColor,
  enableCustomAnchor,
  onToggleEnableCustomAnchor,
}) => {
  const handleSelect = (event) => {
    onSelect(event.target.getAttribute('data-value'));
  };
  const keydownHandler = createSpaceOrEnterHandler(handleSelect);

  const handlePresetSelect = (event) => {
    onSetPresetIcon(
      event.target.getAttribute('data-value-fill'),
      event.target.getAttribute('data-value-stroke'),
    );
  };

  const presetIconKeydownHandler =
    createSpaceOrEnterHandler(handlePresetSelect);

  return (
    <DropdownContainer>
      <Box display="flex" flexWrap="wrap">
        <div className="select-icon-button icon-select">
          <SelectedIcon
            iconId={selectedIcon}
            width={width}
            height={height}
            fill={actionColor}
            shape={shape}
            showBorder={showBorder}
            stroke={iconColor}
          />
        </div>
        {PRESET_ICON_CONFIG.map((config) => (
          <button
            key={`${config.fill}-${config.stroke}`}
            onClick={() => onSetPresetIcon(config.fill, config.stroke)}
            onKeyDown={presetIconKeydownHandler}
            className="select-icon-button icon-select"
            data-value-fill={config.fill}
            data-value-stroke={config.stroke}
            role="button"
            tabIndex={0}
          >
            <SelectedIcon
              iconId={selectedIcon}
              width={width}
              height={height}
              fill={config.fill}
              stroke={config.stroke}
              shape={shape}
              showBorder={showBorder}
            />
          </button>
        ))}
      </Box>
      <Box display="flex" flexWrap="wrap">
        {ICONS.filter((icon) => icon.iconId !== selectedIcon).map((icon) => (
          <button
            key={icon.iconId}
            className="select-icon-button icon-select"
            onClick={() => onSelect(icon.iconId)}
            onKeyDown={keydownHandler}
            data-value={icon.iconId}
          >
            <SelectedIcon
              iconId={icon.iconId}
              width={width}
              height={height}
              fill={actionColor}
              stroke={iconColor}
              shape={shape}
              showBorder={showBorder}
            />
          </button>
        ))}
      </Box>
      <Box
        display="flex"
        padding="0 15px"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <label htmlFor="custom-anchor" style={{ fontSize: '13px' }}>
          Use Custom Anchor
        </label>
        <Switch
          inputProps={{
            name: 'custom-anchor',
            id: 'custom-anchor',
          }}
          disableRipple
          onChange={() => onToggleEnableCustomAnchor(!enableCustomAnchor)}
          checked={enableCustomAnchor}
        />
      </Box>
    </DropdownContainer>
  );
};

IconsDropdown.propTypes = {
  selectedIcon: PropTypes.string.isRequired,
  actionColor: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default IconsDropdown;
