import axios from 'axios';
import forge from 'node-forge';
import React from 'react';
import * as Yup from 'yup';
import {
  API_GET_ALL_PLANS,
  API_PERSONAL_DETAILS,
  HTTP_CLIENT_ENDPOINT,
  WIDGET_NOT_ADDED,
  WIDGET_NOT_ADDED_TIP,
  WIDGET_NOT_SETUP,
  WIDGET_SETUP_FINISHED,
} from './magicValues';
import Cookies from 'js-cookie';

export const makeHash = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getVerificationMessage = (domain) => {
  if (!domain) {
    return '';
  }

  if (!domain.containsWidgetCode) {
    return (
      <>
        {WIDGET_NOT_ADDED}
        <span className="widget-not-added-tip">
          <strong>Tip: </strong>
          {WIDGET_NOT_ADDED_TIP}
        </span>
      </>
    );
  }

  if (!domain.hasWidgetIntegrated) {
    return WIDGET_NOT_SETUP;
  }

  return WIDGET_SETUP_FINISHED;
};

const getLuminance = (rgbArray) => {
  const [lumR, lumG, lumB] = rgbArray.map((component) => {
    const proportion = component / 255;

    return proportion <= 0.03928
      ? proportion / 12.92
      : // eslint-disable-next-line no-restricted-properties
        Math.pow((proportion + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
};

const convertHashToRGB = (colorHash) => {
  let color = colorHash.slice(1);

  if (color.length === 3) {
    color = colorHash
      .slice(1)
      .split('')
      .map(function (colorDigit) {
        return colorDigit + colorDigit;
      })
      .join('');
  }

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  return [r, g, b];
};

const formatRatio = (ratio) => {
  const ratioAsFloat = ratio.toFixed(2);
  const isInteger = Number.isInteger(parseFloat(ratioAsFloat));
  return `${isInteger ? Math.floor(ratio) : ratioAsFloat}`;
};

export const contrastChecker = (color1, color2, ratio) => {
  const colorOneLuminace = getLuminance(convertHashToRGB(color1));
  const colorTwoLuminance = getLuminance(convertHashToRGB(color2));

  const lighterLum = Math.max(colorOneLuminace, colorTwoLuminance);
  const darkerLum = Math.min(colorOneLuminace, colorTwoLuminance);

  const minimumRatio = ratio || 4.5;
  const constrastRatio = formatRatio((lighterLum + 0.05) / (darkerLum + 0.05));

  return constrastRatio > minimumRatio;
};

export const isHexColorLight = (hexColor) => {
  const c = hexColor.substring(1);
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  if (luma < 148) {
    return false;
  }
  return true;
};

export const createSpaceOrEnterHandler =
  (callback, ...args) =>
  (event) => {
    if (
      event.key == ' ' ||
      event.code == 'Space' ||
      event.keyCode == 32 ||
      event.key === 'Enter'
    ) {
      callback(event, ...args);
    }
  };

export const getIconSPositionAndAlignment = (position, scale) => {
  const humanStyles = {};
  const circleStyles = {};

  if (position.alignment.indexOf('right') >= 0) {
    humanStyles.right = position.horizontalMargin;
    humanStyles.marginRight = `${17 * scale}px`;
    circleStyles.right = position.horizontalMargin;
  } else {
    humanStyles.left = position.horizontalMargin;
    humanStyles.marginLeft = `${17 * scale}px`;
    circleStyles.left = position.horizontalMargin;
  }

  if (position.alignment.indexOf('top') >= 0) {
    humanStyles.top = position.verticalMargin;
    humanStyles.marginTop = `${15 * scale}px`;
    circleStyles.top = position.verticalMargin;
  } else {
    humanStyles.bottom = position.verticalMargin;
    humanStyles.marginBottom = `${15 * scale}px`;
    circleStyles.bottom = position.verticalMargin;
  }

  return {
    humanStyles,
    circleStyles,
  };
};

export const generateGradientColors = (hexColor) => {
  // Validate hex format
  if (!hexColor.match(/^#[0-9A-Fa-f]{6}$/)) {
    return {
      start: hexColor,
      end: hexColor,
    };
  }

  // Convert hex to RGB
  const [r, g, b] = convertHashToRGB(hexColor);

  // Check if color is black or white (or very close)
  const isNearBlack = r <= 30 && g <= 30 && b <= 30;
  const isNearWhite = r >= 225 && g >= 225 && b >= 225;

  if (isNearBlack || isNearWhite) {
    return {
      start: hexColor,
      end: hexColor,
    };
  }

  // Calculate perceived brightness
  const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  let endColor;
  if (brightness > 0.5) {
    // For lighter colors, create a darker variant (70%)
    endColor = {
      r: Math.max(0, Math.floor(r * 0.7)),
      g: Math.max(0, Math.floor(g * 0.7)),
      b: Math.max(0, Math.floor(b * 0.7)),
    };
  } else {
    // For darker colors, create a lighter variant (130%)
    endColor = {
      r: Math.min(255, Math.floor(r * 1.3)),
      g: Math.min(255, Math.floor(g * 1.3)),
      b: Math.min(255, Math.floor(b * 1.3)),
    };
  }

  // Convert back to hex
  const endHex =
    '#' +
    endColor.r.toString(16).padStart(2, '0') +
    endColor.g.toString(16).padStart(2, '0') +
    endColor.b.toString(16).padStart(2, '0');

  return {
    start: hexColor,
    end: endHex,
  };
};

const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const HMAC = async (key, message) => {
  if (!isNullOrUndefined(key) && !isNullOrUndefined(message)) {
    const hmac = forge.hmac.create();
    hmac.start('sha256', key);
    hmac.update(message);
    return hmac.digest().toHex();
  }
  return null;
};

export const getPersonalDetails = async (token, gaSessionInfo) => {
  const endpoint = new URL(HTTP_CLIENT_ENDPOINT + API_PERSONAL_DETAILS);

  const params = new URLSearchParams();
  const gclid = Cookies.get('eq_gclid');
  if (gclid) {
    params.append('gclid', gclid);
  }
  if (gaSessionInfo) {
    params.append('ga_session_info', gaSessionInfo);
  }

  endpoint.search = params.toString();

  try {
    const response = await axios.get(endpoint.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data ? response.data : {};
  } catch (error) {
    // todo: handle the error properly
    return {};
  }
};

export const cancelPlan = async (value) => {
  const endpoint = HTTP_CLIENT_ENDPOINT + API_GET_ALL_PLANS;
  const token = localStorage.getItem('accessToken');
  const data = {
    transaction_id: value,
  };
  try {
    const response = await axios.patch(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data ? response.data.message : '';
  } catch (error) {
    // todo: do something withe error
    let errorMessage = 'Something went wrong!';

    if (error?.response?.data?.message) {
      const messageArray = error?.response?.data?.message.split('=');
      errorMessage = messageArray[messageArray.length - 1].trim();
    }
    throw Error(errorMessage);
  }
};

export const addDomainToPlan = async (value) => {
  const endpoint = `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/ml/plans/${value.planID}/domains`;
  const token = localStorage.getItem('accessToken');
  const data = {
    type: value.type,
    domain_names: value.domains,
    active: true,
    description: '',
  };
  try {
    const response = await axios.post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data ? response.data.message : '';
  } catch (error) {
    // todo: do something withe error
    let errorMessage = 'Something went wrong!';

    if (error?.response?.data?.message) {
      const messageArray = error?.response?.data?.message.split('=');
      errorMessage = messageArray[messageArray.length - 1].trim();
    }
    throw Error(errorMessage);
  }
};

export const PasswordLengthValidator = Yup.string().min(
  8,
  'Password is too short - should be 8 chars minimum.',
);

export const PasswordOneLowercaseCharValidator = Yup.string().matches(
  /(?=.*[a-z])/,
  'Password must contain at least 1 lowercase alphabetical character',
);

export const PasswordOneUppercaseCharValidator = Yup.string().matches(
  /(?=.*[A-Z])/,
  'Password must contain at least 1 uppercase alphabetical character',
);

export const PasswordOneNumberValidator = Yup.string().matches(
  /(?=.*[\d])/,
  'Password must contain at least 1 numeric character',
);

export const PasswordOneSpecialCharValidator = Yup.string().matches(
  // Must match all special character [$&+,:;=?@#_`~_\[\]\{\}\/|'<>.^*()%!-]
  /(?=.*[^a-zA-Z\d])/,
  'Password must contain at least one special character,',
);

export const PasswordValidator = Yup.string()
  .concat(PasswordLengthValidator)
  .concat(PasswordOneLowercaseCharValidator)
  .concat(PasswordOneUppercaseCharValidator)
  .concat(PasswordOneNumberValidator)
  .concat(PasswordOneSpecialCharValidator);

export const extractHostname = (url) => {
  let hostname;
  if (url.indexOf('//') > -1) {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[2];
  } else {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[0];
  }

  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split('?')[0];

  return hostname.replace('www.', '');
};
