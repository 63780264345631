import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { setApiSuccessMessage } from '../../store/actions';
import { IconButton } from '../redesign/Controls';
import {
  TypographyH1,
  TypographyH3,
  TypographySubtitle,
} from '../common/styled';

import Drupal from '../../img/Drupal.png';
import Duda from '../../img/Duda.png';
import GTM from '../../img/GTM.png';
import Magento from '../../img/Magento.png';
import Shopify from '../../img/Shopify.png';
import Webflow from '../../img/Webflow.png';
import Weebly from '../../img/Weebly.png';
import Wix from '../../img/Wix.png';
import Wordpress from '../../img/Wordpress.png';
import CopyWidgetKeyAndCode from '../redesign/copy-key-and-code';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useAnalytics } from '@equally-ai-front/common/src';
import { SCHEDULE_A_MEETING } from '../../common/magicValues';
import { DomainKeysAndWidgetUrl } from '@equally-ai-front/common/src/types/business';
import BusinessAPI from '@equally-ai-front/common/src/api/business';
import { ContainedButton } from '../common/ActionButton';
import { getDomainHTMLInstallationCode } from '../../utils/domain.utils';

export const DomainInstallation = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [businessKeyAndWidgetUrl, setBusinessKeyAndWidgetUrl] =
    useState<DomainKeysAndWidgetUrl>({ key: '', widgetUrl: '' });

  const [_, setSearchParams] = useSearchParams();

  const getBusinessDomainKeys = useCallback(async () => {
    if (!currentBusiness) {
      return;
    }
    setIsLoadingCode(true);
    const { data, error, isSuccess } = await BusinessAPI.getDomainKeys(
      currentBusiness.id,
    );

    if (error || !data || !isSuccess) {
      setIsLoadingCode(false);
      return;
    }

    setBusinessKeyAndWidgetUrl(data);
    setIsLoadingCode(false);
  }, [currentBusiness]);

  const sendInstructionsClick = () => {
    const instructions = `Hello,

  Please paste the code below right before the end of your sites body.
  ${getDomainHTMLInstallationCode(businessKeyAndWidgetUrl.key, businessKeyAndWidgetUrl.widgetUrl)}

  Or use one of the integration guides to implement Equally AI
  https://equally.ai/integrations

  Thank you`;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(instructions);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = instructions;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
      document.execCommand('copy');
      textArea.remove();
    }

    dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
    // analytics.track('Installation Pop Up Option Clicked', {
    //   option: 'copy-instructions-button',
    //   url: domainName,
    // });
  };

  const integrationButton = (
    icon: string | undefined,
    link: string,
    text: string,
    style: Record<string, any> | undefined = undefined,
  ) => (
    <Grid
      item
      xs={6}
      sm={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IntegrationLink
        to={`${process.env.REACT_APP_HTTP_INTEGRATIONS_ENDPOINT}/${link}`}
        target="_blank"
        style={style}
        onClick={() => {
          analytics.track('Installation Pop Up Option Clicked', {
            option: 'integration-help',
            integration: link,
            // url: domainName,
          });
        }}
      >
        {icon && <img alt={`${text} integration`} src={icon} />}
        <span>{text}</span>
      </IntegrationLink>
    </Grid>
  );

  const handleScheduleMeetingEvent = () => {
    analytics.track('Installation Pop Up Option Clicked', {
      option: 'schedule-a-meeting',
      // url: domainName,
    });
  };

  const onChoosePlan = () => {
    setSearchParams((prev) => {
      prev.set('show_plan', 'true');
      return prev;
    });
  };

  useEffect(() => {
    void getBusinessDomainKeys();
  }, [getBusinessDomainKeys]);

  return (
    <>
      <div className="header-manage-websites-text">
        <HeadingTypography>Installation</HeadingTypography>
      </div>
      <SectionContainer>
        <div className="modal-panel-sub-title">
          Paste the code below right before the end of your sites body, the URL
          must match your domain so the widget will be displayed instantly:
        </div>
        <CopyWidgetKeyAndCode
          widgetUrl={businessKeyAndWidgetUrl.widgetUrl}
          apiKey={businessKeyAndWidgetUrl.key}
          isLoadingCode={isLoadingCode}
        />

        <Box
          my="24px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <MoneyBack>30-day money-back guarantee</MoneyBack>
          <SelectPlanButton onClick={onChoosePlan}>
            Choose a plan
          </SelectPlanButton>
        </Box>
        <Box my="32px">
          <SubHeading>What&apos;s next?</SubHeading>
          <div className="modal-panel-send-instructions-line-2">
            Please allow up to 48 working hours for an email confirmation that
            the process is complete.
          </div>
        </Box>
      </SectionContainer>
      <SectionContainer>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          my="24px"
          flexWrap="wrap"
          gap="5px"
        >
          <div>
            <SubHeading>Need help?</SubHeading>
            <div className="modal-panel-send-instructions-line-2">
              <InstructionLabel variant="body1">
                Send these instructions to your website admin or &nbsp;
                <StyledLink
                  href={SCHEDULE_A_MEETING}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline' }}
                  onClick={handleScheduleMeetingEvent}
                >
                  Schedule a meeting
                </StyledLink>
              </InstructionLabel>
            </div>
          </div>

          <StyledIconButton
            variant="outlined"
            color="primary"
            disabled={!businessKeyAndWidgetUrl.key}
            onClick={sendInstructionsClick}
          >
            <div className="website-card-controls-btn-text-no-icon">
              Copy instructions
            </div>
          </StyledIconButton>
        </Box>
        <Box my="24px" mx="auto">
          <TypographySubtitle style={{ textAlign: 'center', fontWeight: 500 }}>
            Or
          </TypographySubtitle>
        </Box>
        <Box my="24px" pb={5}>
          <Grid container spacing={2} my="12px">
            {integrationButton(Wordpress, 'wordpress', 'Wordpress')}
            {integrationButton(Wix, 'wix', 'Wix')}
            {integrationButton(GTM, 'tagmanager', 'GTM')}
            {integrationButton(Weebly, 'weebly', 'Weebly')}
            {integrationButton(Shopify, 'shopify', 'Shopify')}
            {integrationButton(Drupal, 'drupal', 'Drupal')}
            {integrationButton(Magento, 'magento', 'Magento')}
            {integrationButton(Webflow, 'webflow', 'Webflow')}
            {integrationButton(Duda, 'duda', 'Duda')}
          </Grid>

          <OutlinedLink
            to={`${process.env.REACT_APP_HTTP_INTEGRATIONS_ENDPOINT}`}
            target="_blank"
            onClick={() => {
              analytics.track('Installation Pop Up Option Clicked', {
                option: 'integration-help',
                integration: 'all-integrations',
                // url: domainName,
              });
            }}
          >
            All Integrations
          </OutlinedLink>
        </Box>
      </SectionContainer>
    </>
  );
};

const SectionContainer = styled(Box)`
  max-width: 72%;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    max-width: 95%;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 160px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @media (pointer: none), (pointer: coarse) {
    height: 40px;
    width: 100%;
  }

  &:hover {
    background: #454284;
    color: #fff;
  }
`;

const InstructionLabel = styled(Typography)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 10, 20, 0.85);
`;

const StyledLink = styled.a`
  color: #7097fa;
`;

export const HeadingTypography = styled(TypographyH1)`
  font-size: 32px;
  margin-right: 5px;
  font-weight: 500;
`;

const IntegrationLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000a14;
  font-weight: 500;
  width: 100%;
  height: 14vh;
  border: 2px solid #cccccc;
  border-radius: 8px;
  padding: 8px 12px;
`;

const SelectPlanButton = styled(ContainedButton)`
  text-transform: initial;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
  border-radius: 8px;
  flex: 1;
`;

const OutlinedLink = styled(Link)`
  border: 2px solid #454284;
  width: 100%;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #000a14;
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 30px;

  &:hover {
    background: #454284;
    color: #fff;
  }
`;

const SubHeading = styled(TypographyH3)`
  font-size: 16px;
  margin-bottom: 5px;
`;

const MoneyBack = styled(TypographyH3)`
  font-size: 18px;
  font-weight: 500;
  margin: 10px auto 12px;
`;
