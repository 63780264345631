import {
  ADD_DEV_DOMAIN,
  ADD_DEV_DOMAIN_SUCCESS,
  ADD_DOMAIN_KEY_SUCCESS,
  ADD_DOMAIN_SUCCESS,
  DELETE_DOMAIN_KEY_SUCCESS,
  DELETE_DOMAIN_SUCCESS,
  GET_ACCESS_SUCCESS,
  GET_API_SAMPLE_CODE_SUCCESS,
  GET_APPEARANCE,
  GET_APPEARANCE_SUCCESS,
  GET_DEV_DOMAINS,
  GET_DEV_DOMAINS_SUCCESS,
  GET_DOMAINS,
  GET_DOMAINS_SUCCESS,
  GET_DOMAIN_KEYS_SUCCESS,
  GET_MORE_DOMAINS,
  RESET_DOMAIN_KEYS,
} from '../actionTypes';
import { APPEARANCE_DEFAULT_SETTINGS } from '../../lib/domains';

const initialState = {
  domains: [],
  devDomains: [],
  serviceUrls: [],
  metaInfo: {},
  appearance: { ...APPEARANCE_DEFAULT_SETTINGS },
  access: {
    name: '',
    email: '',
  },
  keys: [],
  sampleCode: '',
  loading: false,
  loadingMoreDomains: false,
  newDomainLoading: false,
  devDomainSuccessMsg: '',
};

export default function (state = initialState, action) {
  const appearance = action.value;
  switch (action.type) {
    case GET_ACCESS_SUCCESS:
      return {
        ...state,
        access: { ...initialState.access, ...action.value },
        loading: false,
      };
    case GET_APPEARANCE:
      return {
        ...state,
        loading: true,
      };
    case GET_APPEARANCE_SUCCESS:
      if (!appearance.widgetConfigSitePosition.alignment) {
        appearance.widgetConfigSitePosition.alignment = 'bottom-right';
      }
      if (!appearance.widgetConfigSitePosition.horizontalMargin) {
        appearance.widgetConfigSitePosition.horizontalMargin = '20px';
      }
      if (!appearance.widgetConfigSitePosition.verticalMargin) {
        appearance.widgetConfigSitePosition.verticalMargin = '20px';
      }
      if (!appearance.widgetConfigMobilePosition.alignment) {
        appearance.widgetConfigMobilePosition.alignment = 'bottom-right';
      }
      if (!appearance.widgetConfigMobilePosition.horizontalMargin) {
        appearance.widgetConfigMobilePosition.horizontalMargin = '20px';
      }
      if (!appearance.widgetConfigMobilePosition.verticalMargin) {
        appearance.widgetConfigMobilePosition.verticalMargin = '20px';
      }
      if (!appearance.widgetConfigSiteSize.diameterDesktop) {
        appearance.widgetConfigSiteSize.diameterDesktop = '45px';
      }
      if (!appearance.widgetConfigSiteSize.diameterMobile) {
        appearance.widgetConfigSiteSize.diameterMobile = '30px';
      }
      if (!appearance.widgetConfigSiteAdditional.widgetActivationAnchor) {
        appearance.widgetConfigSiteAdditional.widgetActivationAnchor =
          'icon-human';
      }
      if (
        appearance.widgetConfigSiteAdditional.enableAriaAttributes === undefined
      ) {
        appearance.widgetConfigSiteAdditional.enableAriaAttributes = false;
      }
      if (
        appearance.widgetConfigSiteAdditional.enableKeyboardNavigation ===
        undefined
      ) {
        appearance.widgetConfigSiteAdditional.enableKeyboardNavigation = false;
      }
      if (!appearance.widgetConfigSiteAdditional.defaultLanguage) {
        appearance.widgetConfigSiteAdditional.defaultLanguage = 'en';
      }
      if (appearance.widgetConfigSiteAdditional.enableSkipLinks === undefined) {
        appearance.widgetConfigSiteAdditional.enableSkipLinks = false;
      }
      if (appearance.widgetConfigSiteAdditional.enableSafeMode === undefined) {
        appearance.widgetConfigSiteAdditional.enableSafeMode = false;
      }
      if (
        appearance.widgetConfigSiteAdditional.applyContrastFixes === undefined
      ) {
        appearance.widgetConfigSiteAdditional.applyContrastFixes = false;
      }
      if (
        appearance.widgetConfigSiteAdditional.allowDisableOffScreen ===
        undefined
      ) {
        appearance.widgetConfigSiteAdditional.allowDisableOffScreen = false;
      }
      if (
        appearance.widgetConfigSiteAdditional.accessibilityStatementLink ===
        undefined
      ) {
        appearance.widgetConfigSiteAdditional.accessibilityStatementLink = '';
      }
      if (!appearance.widgetConfigSiteColoring.actionColor) {
        appearance.widgetConfigSiteColoring.actionColor = '#ffffff';
      }
      if (!appearance.widgetConfigSiteColoring.iconObjectColor) {
        appearance.widgetConfigSiteColoring.iconObjectColor = '#ffffff';
      }
      if (!appearance.widgetConfigSiteColoring.iconBorder) {
        appearance.widgetConfigSiteColoring.iconBorder = false;
      }
      if (!appearance.widgetConfigSiteSize.iconShape) {
        appearance.widgetConfigSiteSize.iconShape = 'circle';
      }
      return { ...state, appearance, loading: false };
    case GET_DOMAINS:
      return { ...state, loading: true };
    case GET_MORE_DOMAINS:
      return { ...state, loadingMoreDomains: true };
    case GET_DOMAINS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const domains = action.value.message
        ? action.value.message
        : action.value;
      return {
        ...state,
        domains,
        loading: false,
        loadingMoreDomains: false,
        metaInfo: action.value.metaInfo ?? {
          ...state.metaInfo,
          page: 1,
          Next: state.metaInfo.PerPage < state.metaInfo.TotalCount ? 2 : null,
        },
      };
    case ADD_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: [...(state.domains || []), { name: action.value }],
      };
    case DELETE_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: state.domains.filter((domain) => domain.name !== action.value),
      };
    case GET_DOMAIN_KEYS_SUCCESS:
      return { ...state, keys: action.value };
    case GET_API_SAMPLE_CODE_SUCCESS:
      return {
        ...state,
        sampleCode: action.value.code,
      };
    case ADD_DOMAIN_KEY_SUCCESS:
      return { ...state, keys: [...state.keys, { key: action.value }] };
    case DELETE_DOMAIN_KEY_SUCCESS:
      return {
        ...state,
        keys: state.keys.filter((key) => key.key !== action.value),
      };
    case RESET_DOMAIN_KEYS:
      return {
        ...state,
        keys: [],
        sampleCode: '',
      };
    case GET_DEV_DOMAINS:
      return { ...state, loading: true };
    case GET_DEV_DOMAINS_SUCCESS:
      return { ...state, devDomains: action.value, loading: false };
    case ADD_DEV_DOMAIN:
      return {
        ...state,
        newDomainLoading: true,
      };
    case ADD_DEV_DOMAIN_SUCCESS:
      return {
        ...state,
        newDomainLoading: false,
        devDomainSuccessMsg: action.value,
      };
    default: {
      return state;
    }
  }
}
