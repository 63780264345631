import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';
import { useAuth } from '../../providers/auth-context';
import { setApiError } from '../../store/actions';
import styled from '@emotion/styled';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const PasswordReset = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const user = useSelector((state) => state.user.user);
  const analytics = useAnalytics();
  const { resetPassword } = useAuth();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setErrors((prev) => ({ ...prev, newPassword: '' }));
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setErrors((prev) => ({ ...prev, confirmPassword: '' }));
  };
  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setErrors((prev) => ({ ...prev, oldPassword: '' }));
  };

  useEffect(() => {
    analytics.page({
      name: 'Password Reset Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPassword = async () => {
    const validationErrors = {};
    if (!oldPassword)
      validationErrors.oldPassword = 'Old password is required.';
    if (!newPassword)
      validationErrors.newPassword = 'New password is required.';
    if (!confirmPassword)
      validationErrors.confirmPassword = 'Confirm password is required.';
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await resetPassword(oldPassword, newPassword);
    } catch (error) {
      dispatch(setApiError('An error occurred while resetting the password.'));
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        '@media screen and (max-width: 800px)': {
          maxWidth: '100%',
        },
      }}
    >
      <div className="header-page-text">Password reset</div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '30px',
        }}
      >
        <TextFieldWrapper>
          <TextField
            className={classes.input}
            label="Email"
            disabled
            defaultValue={user.email}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            className={classes.input}
            label="Old password"
            type="password"
            autoComplete="old-password"
            onChange={handleOldPasswordChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            className={classes.input}
            label="New password"
            type="password"
            autoComplete="new-password"
            variant="outlined"
            onChange={handleNewPasswordChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            className={classes.input}
            label="Confirm new password"
            onChange={handleConfirmPasswordChange}
            type="password"
            autoComplete="new-password"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </TextFieldWrapper>
        <PrimaryButton
          color="primary"
          style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
          variant="contained"
          onClick={handleResetPassword}
        >
          Update Password
        </PrimaryButton>
      </Box>
    </Box>
  );
};

const TextFieldWrapper = styled(Box)`
  margin-bottom: 15px;

  & .MuiTextField-root {
    width: 100%;
  }

  & .MuiFormHelperText-root {
    margin: 0;
  }
`;
