import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import styled from '@emotion/styled';
import {
  CartAPI,
  CartStore,
  PlanTemplateItem,
} from '@equally-ai/cart-checkout';
import { useCart } from '../../../../../providers/cart-provider';

import PaypalButton from '../paypal-button';
import CartItem from '../cart-Item';

import {
  OutlinedButton,
  ContainedButton,
} from '../../../../../components/common/ActionButton';
import { useDispatch } from 'react-redux';
import {
  setApiError,
  setApiSuccessMessage,
} from '../../../../../store/actions';
import { GenericInput } from '../../../../../components/accessibility-components/generic-input';
import { PrimaryButton } from '../../../../../components/redesign/Controls';
import { ArrowBack } from '@mui/icons-material';
import { Business } from '@equally-ai-front/common/src/types/business';
import { StripeCheckout } from '@equally-ai/cart-checkout/src/components/stripe-checkout';
import { StripeService } from '@equally-ai/cart-checkout/src/api/stripe';
import { PAYMENT_PROVIDER_ID } from '@equally-ai/cart-checkout/src/types';

export interface CheckoutState {
  total: number | null;
  discountAmount?: number;
  isCouponValid?: boolean;
  planID: string | null;
  checkoutID: number | null;
  isServiceOnly?: boolean;
  isLoading: boolean;
  cartID?: number;
  providerID: number;
}

interface CheckoutModalProps {
  onClose: () => void;
  onComplete: () => void;
  setAllowMaxHeight: (value: boolean) => void;
  currentBusiness: Business;
  domainId?: number;
}

const attachToCurrency = (amount: number) => {
  return `$${amount.toLocaleString()}`;
};

const getPlanTemplateQuantityOrQuantity = (
  planTemplate: PlanTemplateItem,
  quantity: number,
) => {
  return quantity >= planTemplate.domain_size
    ? quantity
    : planTemplate.domain_size;
};

const Summary = ({
  onClose,
  setAllowMaxHeight,
  onComplete,
  currentBusiness,
  domainId,
}: CheckoutModalProps) => {
  const {
    store: { cart },
    actions,
  } = useCart();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [stripeSessionSecret, setStripeSessionSecret] = useState(undefined);
  const [isLoadingStripeSession, setIsLoadingStripeSession] = useState(false);

  const [checkout, setCheckout] = useState<CheckoutState>({
    total: null,
    planID: null,
    checkoutID: null,
    isLoading: false,
    providerID: 0,
  });
  const [coupon, setCoupon] = useState('');
  const getStripeCheckoutSession = async (
    providerId: number,
    cartId: number,
    checkoutId: number,
  ) => {
    setIsLoadingStripeSession(true);
    const { data } = await StripeService.getCheckoutSession({
      businessId: currentBusiness.id,
      providerId,
      cartId,
      checkoutId,
    });
    setIsLoadingStripeSession(false);
    setStripeSessionSecret(data?.session_client_secret);
  };
  useEffect(() => {
    const handleCartCheckout = async () => {
      if (!cart) {
        return;
      }
      setCheckout({ ...checkout, isLoading: true });
      const deSerializeQueryParams = (str: URLSearchParams) => {
        return str.get('coupon_name') || undefined;
      };

      const queryString: string | undefined =
        deSerializeQueryParams(searchParams);
      try {
        const { data: checkoutCart } = await CartAPI.checkout(
          cart.id,
          queryString || '',
        );
        const isServicesOnly = (newCheckout: { cart?: CartStore }) => {
          return (
            newCheckout?.cart?.cart_items?.length === 1 &&
            newCheckout?.cart?.cart_items?.[0].plan_template.product_type ===
              'service'
          );
        };

        setCheckout({
          cartID: checkoutCart.cart?.id,
          total: checkoutCart.total_price,
          discountAmount: checkoutCart.cart?.discount_amount,
          isCouponValid: checkoutCart.cart?.is_coupon_valid,
          planID: checkoutCart.plans[0].external_id,
          checkoutID: checkoutCart.id,
          isServiceOnly: isServicesOnly(checkoutCart),
          providerID: checkoutCart.provider_id,
          isLoading: false,
        });

        if (checkoutCart.provider_id === PAYMENT_PROVIDER_ID.STRIPE) {
          getStripeCheckoutSession(3, checkoutCart.cart?.id, checkoutCart.id);
          setAllowMaxHeight(true);
        }
      } catch (error) {
        setCheckout({ ...checkout, isLoading: false });
        dispatch(setApiError('Unable to Checkout, Try Again Later'));
      }
    };
    void handleCartCheckout();
  }, []);

  const handleExitCheckout = () => {
    if (checkout.planID) {
      actions.copyCartOnExitCheckout();
    }

    setCheckout({
      total: null,
      planID: null,
      checkoutID: null,
      isLoading: false,
      providerID: 0,
    });

    setCoupon('');

    onClose();
  };

  const handleCouponApply = async () => {
    const { data, isSuccess } = await CartAPI.getAuthCart(coupon);
    if (!isSuccess) {
      return;
    }

    if (data?.is_coupon_valid) {
      dispatch(setApiSuccessMessage('Coupon applied successfully.'));
      actions.updateCartState(data);

      setSearchParams((prev) => {
        prev.set('coupon_name', coupon);
        return prev;
      });
    } else {
      dispatch(setApiError('Unable to apply coupon, Try Again Later'));
    }
  };

  const handleRemoveItem = (itemId: number) => {
    actions.removeFromCart(itemId);
    if ((cart?.cart_items || []).length <= 1) {
      onClose();
    }
  };

  return (
    <ContentWrapper>
      <PlansButton onClick={handleExitCheckout} color="primary">
        <ArrowBack style={{ marginRight: '5px' }} />
        <span className="plan">Plans</span>
      </PlansButton>
      {!stripeSessionSecret &&
        checkout.providerID === PAYMENT_PROVIDER_ID.PAYPAL && (
          <>
            <Box>
              {cart?.cart_items.map((item) => (
                <CartItem
                  quantity={getPlanTemplateQuantityOrQuantity(
                    item.plan_template,
                    item.quantity,
                  )}
                  trialDuration={item.plan_template.trial_duration}
                  trialDurationUnit={item.plan_template.trial_duration_unit}
                  unit={item.plan_template.duration_unit}
                  total={item.plan_template.price}
                  title={item.plan_template.product_type}
                  name={item.plan_template.name}
                  key={item.id}
                  itemId={item.id}
                  removeItem={() => handleRemoveItem(item.id)}
                  displayRemoveButton
                />
              ))}
            </Box>
            {/*<Box mt="32px">*/}
            {/*  <PricesListContainer>*/}
            {/*    {cart?.discount_amount || checkout.discountAmount ? (*/}
            {/*      <TotalPriceContainer>*/}
            {/*        <Typography style={{ opacity: 0.6 }} variant={'body1'}>*/}
            {/*          Discount*/}
            {/*        </Typography>*/}
            {/*        <Price>*/}
            {/*          <DiscountTag*/}
            {/*            discountText={attachToCurrency(*/}
            {/*              cart?.discount_amount || checkout.discountAmount || 0,*/}
            {/*            ).toLocaleString()}*/}
            {/*          />*/}
            {/*        </Price>*/}
            {/*      </TotalPriceContainer>*/}
            {/*    ) : undefined}*/}
            {/*    <Spacer />*/}
            {/*    <TotalPriceContainer>*/}
            {/*      <Typography variant={'body1'} style={{ fontSize: '20px' }}>*/}
            {/*        Total*/}
            {/*      </Typography>*/}
            {/*      <Price>${checkout.total ?? cart?.total_price} </Price>*/}
            {/*    </TotalPriceContainer>*/}
            {/*  </PricesListContainer>*/}
            {/*  {!checkout.planID && (*/}
            {/*    <>*/}
            {/*      <CouponContainer>*/}
            {/*        <GenericInput*/}
            {/*          ariaProps={{ 'aria-label': 'Enter coupon code' }}*/}
            {/*          label="Enter Coupon Code"*/}
            {/*          id="coupon"*/}
            {/*          type="text"*/}
            {/*          value={coupon}*/}
            {/*          variant="outlined"*/}
            {/*          onChange={(e) => setCoupon(e.target.value)}*/}
            {/*        />*/}

            {/*        <OutlinedButton*/}
            {/*          onClick={handleCouponApply}*/}
            {/*          disabled={!coupon}*/}
            {/*          style={{ marginLeft: '10px', textTransform: 'initial' }}*/}
            {/*        >*/}
            {/*          Apply Coupon*/}
            {/*        </OutlinedButton>*/}
            {/*      </CouponContainer>*/}

            {/*      <ContainedButton*/}
            {/*        onClick={handleCartCheckout}*/}
            {/*        fullWidth*/}
            {/*        style={{ textTransform: 'initial' }}*/}
            {/*      >*/}
            {/*        Select a payment method*/}
            {/*      </ContainedButton>*/}
            {/*    </>*/}
            {/*  )}*/}

            {/*  {checkout.planID && checkout.providerID === 2 && (*/}
            {/*    <PaypalButton*/}
            {/*      closeModal={onComplete}*/}
            {/*      planId={checkout.planID}*/}
            {/*      domainId={domainId}*/}
            {/*      checkout={checkout}*/}
            {/*      businessId={currentBusiness.id}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</Box>*/}
          </>
        )}
      {stripeSessionSecret && (
        <StripeCheckout clientSecret={stripeSessionSecret} />
      )}

      {(checkout.isLoading || isLoadingStripeSession) && (
        <SpinnerContainer>
          <CircularProgress />
        </SpinnerContainer>
      )}
    </ContentWrapper>
  );
};

const CouponContainer = styled.div`
  border-top: 1px solid #aaa;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;

  & > div {
    width: 70%;
    margin-right: auto;
  }
`;

const PlansButton = styled(PrimaryButton)`
  display: flex;
  justify-content: flex-start;
  width: max-content;

  position: absolute;
  top: -2%;
  left: 10px;
  z-index: 999;

  @media screen and (max-width: 600px) {
    top: -1%;
    left: -4%;

    & > .plan {
      display: none;
    }
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  max-height: 95vh;
  min-height: 500px;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  min-width: 45%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media screen and (max-width: 600px) {
    padding: 0 12px;
    min-height: 100%;
  }
`;

const TotalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Spacer = styled.div`
  margin: 1vh 0;
`;
const PricesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 20000;
  background-color: rgba(225, 225, 225, 0.6);
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Price = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 90%;

  & > sub {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: line-through;

    vertical-align: bottom;
  }
`;

const ModalContent = styled.div`
  color: rgba(0, 10, 20, 0.85);

  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  font-size: 16px;

  margin-top: 22px;
`;

const DiscountContainer = styled.div``;

interface DiscountTagProps {
  discountText: string;
}

// eslint-disable-next-line react/prop-types
const DiscountTag = ({ discountText }: DiscountTagProps) => (
  <DiscountContainer>
    <Typography variant="body1">-{discountText}</Typography>
  </DiscountContainer>
);

export default Summary;
