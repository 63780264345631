import { getBusinesses } from '@equally-ai-front/common/src/redux/business-slice/business';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useAuth } from '../providers/auth-context';
import { AuthContainer } from './auth-container/auth-container';
import { BusinessRedirect } from './business-redirect';
import { BusinessFormContainer } from './on-boarding-container/on-boarding-container';
import { ProtectedRoute } from './protected-route';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import { TopBar } from '../components/top-bar/top-bar';
import { useMediaQuery } from '../hooks/useMediaQuery';
import {
  NavigationBar,
  NavigationBarView,
} from '../components/navigation-bar/navigation-bar';
import { PageContentContainer } from '../components/common/page-content-container/page-content-container';
import { useAnalytics } from '@equally-ai-front/common/src';
import styled from '@emotion/styled';

export const AppRedirect = () => {
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const businesses = useSelector((state: any) => state.business.businesses);
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  const { domains, loadingDomains } = useSelector((state: any) => ({
    domains: state.userProducts.domains,
    loadingDomains: state.userProducts.loading,
  }));

  const [_, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const { isAuthenticated, verifyAuthentication } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isVerifiedAuthenticationRef = useRef(false);

  useEffect(() => {
    if (isVerifiedAuthenticationRef.current) {
      return;
    }
    const verifyAuth = async () => {
      await verifyAuthentication();
      isVerifiedAuthenticationRef.current = true;
      setIsLoading(false);
    };

    void verifyAuth();
  }, [verifyAuthentication, isVerifiedAuthenticationRef]);

  useEffect(() => {
    if (!isAuthenticated || !personalDetails || businesses) {
      return;
    }

    // @ts-ignore
    dispatch(getBusinesses());
  }, [businesses, dispatch, personalDetails, isAuthenticated]);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    // @ts-ignore
    dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
  }, [currentBusiness, dispatch]);

  useEffect(() => {
    if (!isAuthenticated || !currentBusiness || loadingDomains || !domains) {
      return;
    }

    if (domains.length === 0 && personalDetails.newAccount) {
      setSearchParams((prev) => {
        prev.set('from_onboarding', 'true');
        return prev;
      });
    }
  }, [businesses, domains, loadingDomains, isAuthenticated, personalDetails]);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '60vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isAuthenticated ? undefined : <AuthContainer />}

          <Routes>
            {businesses && personalDetails?.id && (
              <Route
                path={`:businessSlug?/*`}
                element={
                  <ProtectedRoute>
                    <BusinessRedirect />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path={`on-boarding/*`}
              element={
                <ProtectedRoute>
                  <ContainerWithLayout>
                    <BusinessFormContainer />
                  </ContainerWithLayout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};

const DRAWER_WIDTH = 270;
export const ContainerWithLayout = ({
  children,
  view,
}: {
  children: any;
  view?: NavigationBarView;
}) => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const { isAuthenticated } = useAuth();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setIsMobileOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsMobileOpen(true);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setIsMobileOpen(!isMobileOpen);
    }
  };

  return (
    <>
      <TopBar
        handleDrawerOpen={handleDrawerOpen}
        isAuthenticated={isAuthenticated}
      />
      <NavigationBar
        view={view || 'app'}
        drawerWidth={DRAWER_WIDTH}
        isMobileOpen={isMobileOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        isAuthenticated={isAuthenticated}
      />
      <Spacer />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: '#ffffff',
          marginLeft: `${DRAWER_WIDTH}px`,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,

          marginTop: '90px',
          paddingTop: '30px',
          height: 'calc(100vh - 90px)',
          overflow: 'hidden auto',
          '@media screen and (max-width: 800px)': {
            marginTop: '71px',
            paddingTop: '19px',
            width: '100%',
            marginLeft: '0',
          },
          '@media print': {
            width: '100%',
            marginLeft: '0',
            height: 'calc(100vh - 90px)',
            overflow: 'auto',
          },
        }}
      >
        <PageContentContainer>{children}</PageContentContainer>
      </Box>
    </>
  );
};

const Spacer = styled(Box)`
  background-color: #f1f2f3;
  height: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 80px;

  @media screen and (max-width: 800px) {
    top: 70px;
    height: 1px;
  },
`;
