export type ProductType = 'widget' | 'developer' | 'flowy' | 'service';

export interface PlanTemplateItem {
  id: number;
  name: string;
  type: string;
  product_type: ProductType;
  product_sub_type: string;
  description: string;
  duration: number;
  duration_unit: string;
  domain_size: number;
  page_size: number;
  trial_duration: number;
  trial_duration_unit: string;
  price: number;
  currency: string;
  external_id: string;
  business_id: number;
  product_id: string;
  is_visible: true;
  features: string[];
  created_at: string;
  updated_at: string;
}

export interface CartItem {
  id: number;
  cart_id: number;
  quantity: number;
  plan_template_id: number;
  plan_template: PlanTemplateItem;
}

export interface CartStore {
  id: number;
  user_id: number;
  expiration_date: string;
  is_active: boolean;
  cart_items: CartItem[];
  total_price: number;
  discount_amount: number;
  is_coupon_valid: boolean;
  created_at: string;
  updated_at: string;
}

export interface CartItemPayload {
  quantity: number;
  plan_template_id: number;
}

export interface ApiResponsePayload<T = any> {
  data: T;
  isSuccess: boolean;
  error: string | undefined;
}

export type ApprovePayload = {
  transaction_id: number;
  checkout_id: number;
  custom_id: string;
  business_id: number;
};

export type PlanTemplatesMap = {
  [key: number]: PlanTemplateItem;
};

export type PlanTemplatesIdToNameMap = {
  [key: string | number]: string;
};

export type PlanTemplatesNameMap = {
  [key: string]: PlanTemplateItem[];
};

export type PriceTemplateState = {
  prices: PlanTemplateItem[];
  isLoading: boolean;
  planTemplatesMap: PlanTemplatesMap;
  planTemplatesNameMap: PlanTemplatesNameMap;
};

export interface useCartType {
  handleCartUpdate: (cartItems: CartItemPayload[]) => Promise<void>;
  isLoading: boolean;
  resolveCartOnCheckout: () => Promise<void>;
  copyCartOnExitCheckout: () => Promise<void>;
  addToCart: (id: number, duration: string, quantity?: number) => Promise<void>;
  removeFromCart: (id: number) => Promise<void>;
  clearCart: () => Promise<void>;
  increaseQuantity: (id: number) => void;
  decreaseQuantity: (id: number) => void;
  setItemQuantity: (id: number, quantity: number) => void;
  onPaymentApproved: (
    subscriptionId: number,
    checkoutId: number,
    customId: string,
    businessId: number,
    onSuccess: (subscription: any) => Promise<void>,
    onError: (error: string) => void,
  ) => Promise<void>;
}

export interface usePlanTemplatesType {
  fetchPrices: (type: string) => Promise<void>;
  priceTemplates: PriceTemplateState;
}

export enum CheckoutStatuses {
  COMPLETE = 'complete',
}

export type StripeSessionResponse = {
  session_id: string;
  checkout_id: string;
  status: CheckoutStatuses;
  client_secret: string | null;
  payment_method: {
    type: string;
  };
  duration: number;
  duration_unit: string;
  customer_details: {
    name: string;
    email: string;
  };
  total_price: number;
  currency: string;
  subscription_external_id: string;
  plan_template_name: string;
  credits: number;
  type: string;
  expiration_date: string;
};

export enum PAYMENT_PROVIDER_ID {
  PAYPAL = 2,
  STRIPE = 3,
}
