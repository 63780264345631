import { Box } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { CustomToolTip } from '@equally-ai-front/common/src/components/custom-tooltip/CustomToolTip';
import Zoom from '@mui/material/Zoom';
import { Scan } from '@equally-ai-front/common/src/types/issues';

interface ScanFailedStatusProps {
  scan: Scan;
}

export const ScanFailedStatus = (props: ScanFailedStatusProps) => {
  const { scan } = props;
  return (
    <Wrapper>
      <CustomToolTip
        title={scan.failure_reason}
        placement="top"
        TransitionComponent={Zoom}
        hidden={!scan.failure_reason}
        arrow
      >
        <ScanStatusContent>{scan.status}</ScanStatusContent>
      </CustomToolTip>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  cursor: pointer;
`;

export const ScanStatusContent = styled(Box)`
  background-color: #ff999a1a;
  color: #ff999a;
  border: 1px solid #ff999a;
  border-radius: 3px;
  height: 25px;
  font-size: 12px;
  width: 60px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 640px) {
    margin-left: 0;
  }
`;
