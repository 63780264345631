export const APPEARANCE_DEFAULT_SETTINGS = {
  widgetConfigSitePosition: {
    alignment: 'bottom-right',
    horizontalMargin: '20px',
    verticalMargin: '20px',
  },
  widgetConfigMobilePosition: {
    alignment: 'bottom-right',
    horizontalMargin: '20px',
    verticalMargin: '20px',
  },
  widgetConfigSiteColoring: {
    accentColor: '#000000',
    headerColor: '#ffffff',
    actionColor: '#000000',
    humanColor: '#ffffff',
    backgroundColor: '#000000',
    iconObjectColor: '#ffffff',
    iconBorder: false,
  },
  widgetConfigSiteSize: {
    diameterDesktop: '50px',
    diameterMobile: '49px',
    iconShape: 'circle',
  },
  widgetConfigSiteAdditional: {
    enableAriaAttributes: true,
    widgetActivationAnchor: 'icon-human',
    defaultLanguage: 'en',
    enableSkipLinks: true,
    enableSafeMode: false,
    enableKeyboardNavigation: true,
    applyContrastFixes: false,
    allowDisableOffScreen: false,
    accessibilityStatementLink: '',
  },
};
